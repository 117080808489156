@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";

.PersonJobCard {
  background-color: $background_primary_light_color;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  height: 400px;
  position: relative;
  width: 363px;

  .container-details {
    align-items: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 6px 18px #0000002b;
    display: flex;
    flex-direction: column;
    height: 94px;
    justify-content: center;
    position: absolute;
    right: -115px;
    top: 35px;
    width: 168px;
    z-index: 1;

    .separator {
      background-color: $text_secondary_dark_color;
      border: none;
      color: $text_secondary_dark_color;
      display: block;
      height: 1px;
      margin: 0;
      opacity: 0.2;
      width: 80%;
    }

    button {
      align-items: center;
      background-color: transparent;
      border: none;
      color: $text_secondary_dark_color;
      cursor: pointer;
      display: flex;
      font-family: $primary_font_book;
      font-size: pxToRem(19px);
      font-weight: 700;
      height: 42px;
      justify-content: center;
      position: relative;
      width: 100%;

      img {
        height: 21px;
        left: 10px;
        position: absolute;
        width: 21px;
      }

      &:hover {
        filter: brightness(0.2);
      }
    }
  }

  .switch-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      background-color: $background_primary_light_color;
      border: none;
      cursor: pointer;
      font-size: 10px;
      margin: 20px 20px 0 0;
      outline: none;
    }
  }

  .container-person-info {
    border-bottom: 1px solid #e8e8e8;
    margin: 0 30px;
    padding-bottom: 19px;

    .title-card-person {
      cursor: pointer;
      
      &.margin {
        margin-top: 40px;
      }
      span {
        color: $text_primary_dark_color;
        font-family: $primary_font_demi;
        font-size: pxToRem(21px);
        margin: 0;
      }

      p {
        color: $text_primary_dark_color;
        font-family: $primary_font_book;
        font-size: pxToRem(16px);
        margin: 6px 0 0 0;
      }
    }

    .tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      margin-top: 20px;

      .tag {
        align-items: center;
        background-color: white;
        border-radius: 30px;
        border: 1px solid #cccccc;
        color: $text_primary_dark_color;
        display: flex;
        font-family: $primary_font_book;
        font-size: pxToRem(13px);
        height: 30px;
        margin: 0;
        outline: none;
        padding: 7px 10px;
        width: max-content;

        p {
          margin: 0;
        }
      }
    }

    .container-badges {
      margin-top: 45px;

      .two-badge {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
      }

      .badge {
        align-items: center;
        display: flex;

        p {
          color: $text_primary_dark_color;
          font-family: $primary_font_book;
          font-size: pxToRem(13px);
        }

        img {
          height: 17px;
          margin-right: 7px;
          width: 19px;
        }

        &.last {
          margin-top: 10px;
        }
      }
    }
  }

  .container-buttons {
    display: flex;
    justify-content: space-between;
    margin: 24px 30px;

    .Button {
      border-radius: 16px;
      font-size: pxToRem(15px);
      padding: 15px 18px;
    }
  }
}
