@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Login {
  align-items: center;
  background-color: #010232;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 60px 15px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    height: 100vh;
    justify-content: space-between;
    padding: 60px 30px;
    width: 100%;
  }

  .background {
    mix-blend-mode: color-dodge;
    opacity: 1;
    position: fixed;
    width: 1524px;

    &.one {
      right: 70%;
      top: -65%;
    }

    &.two {
      left: 70%;
      top: 25%;
    }
  }

  .container-login {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      width: 80%;
    }

    .info {
      display: flex;
      justify-content: center;

      .info-header {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 20px 0;

        p {
          color: $text_primary_light_color;
          font-family: $primary_font_book;
          font-size: pxToRem(17px);
          margin: 0;

          &:first-child {
            font-family: $primary_font_demi;
            font-size: pxToRem(13px);
          }

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(25px);
          }
        }

        img {
          width: 75%;
        }

        h1 {
          color: $text_primary_light_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(68px);
          margin: 0 0 33px 0;

          span {
            color: #01c7f0;
            display: inline-block;
            transform: matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
          }
        }
      }
    }
  }

  form {
    align-items: center;
    background-color: #000255;
    border-radius: 14px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    max-width: 640px;
    width: 100%;
    z-index: 10;

    @include breakpoint-for(desktop-small) {
      margin: 0;
      max-width: 640px;
    }
  }

  .enter-login {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 50px 15px;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      padding: 120px 0;
      width: 418px;
    }

    .error {
      color: red;
    }

    .password-box {
      margin-top: 20px;

      .redefine {
        color: white;
        font-family: $primary_font_demi;
        margin-bottom: 10px;
        text-align: right;
        text-decoration: unset;
      }
    }
          
    .Input {
      color: white;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        max-width: 418px;
      }

      input {
        background-color: #000255;
        border: 1px solid #7b7eff;

        &::placeholder {
          color: white;
        }
      }
    }

    div {
      display: flex;
      flex-direction: column;

      label {
        color: $text_primary_light_color;
        font-family: $primary_font_demi;
        font-size: 17px;
        margin-bottom: 14px;
      }

      input {
        background: #2f3055;
        border: none;
        color: $text_primary_light_color;
        font-size: 25px;
        height: 56px;
        width: 100%;
        opacity: 1;
        outline: none;
      }
    }

    button {
      background-color: #03067d;
      border: none;
      color: $text_primary_light_color;
      cursor: pointer;
      font-family: $primary_font_demi;
      font-size: 17px;
      height: 56px;
      margin-top: 24px;
      width: 100%;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .creators-info {
    align-items: center;
    bottom: 0;
    display: flex;
    position: fixed;
    right: 100px;

    div {
      p {
        color: $text_primary_light_color;
        font-family: $primary_font_book;
        font-size: 9px;

        strong {
          font-family: $primary_font_demi;
          font-size: 18px;
        }
      }
    }

    img {
      height: 94px;
      width: 120px;
    }
  }
}
