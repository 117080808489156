@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.AllFavorites {
  .list-favorites {
    background-color: $background_primary_light_color;
    display: flex;
    flex-direction: column;
    min-height: 575px;
    padding: 52px 60px;

    .title-container {
      align-items: center;
      background-color: $background_light_grey;
      border-radius: 9px;
      display: flex;
      height: 64px;
      padding: 0 51px;

      span {
        color: text_secondary_dark_color;
        font-size: pxToRem(19px);
        font-family: $primary_font_demi;
      }
    }

    .all-list-favorites {
      margin-top: 88px;
      padding: 0 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      .list-favorite {
        background-color: white;
        border-radius: 16px;
        box-shadow: 0px 3px 11px #00000005;
        display: block;
        height: 273px;
        padding: 31px 24px;
        width: 230px;

        .info-list {
          align-items: flex-start;
          display: flex;
          flex-direction: column;

          div {
            p {
              color: $background_primary_blue_color;
              cursor: pointer;
              font-family: $primary_font_demi;
              font-size: pxToRem(19px);
              margin: 0;
            }

            hr {
              background: #00016E 0% 0% no-repeat padding-box;
              border-radius: 5px;
              height: 5px;
              margin: 8px 0;
              opacity: 0.11;
              width: 65px;
            }

            span {
              color: $background_primary_blue_color;
              display: block;
              font-family: $primary_font_demi;
              font-size: pxToRem(19px);
              margin: 25px 0;
            }
          }

          .Button {
            font-size: pxToRem(19px);
            height: 55px;
            padding: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
