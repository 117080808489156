@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.CardTableJob {
  align-items: flex-start;
  background: #fafbfb;
  border-radius: 20px;
  border: 1px solid #eaedef;
  box-shadow: 0px 3px 11px #00000005;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 15px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    height: 99px;
    padding: 17px 58px;
    align-items: center;
    flex-direction: row;
  }

  p {
    color: $text_primary_dark_color;
    font-family: $primary_font_demi;
    font-size: pxToRem(16px);

    @include breakpoint-for(desktop-small) {
      padding-right: 20px;
      font-size: pxToRem(19px);
    }
  }

  .info-card-table {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      justify-content: initial;
      width: 70%;
    }
  }

  .buttons-card-table {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 90%;

    @include breakpoint-for(desktop-small) {
      justify-content: space-between;
      width: 50%;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      height: 100%;
      width: 10%;

      img {
        height: 49px;
        width: 49px;
      }
    }

    .computer-button {
      height: 16px;
      margin: 0 4px;
      position: relative;
      top: 3px;
      width: 16px;
    }
  }

  .logo {
    height: 50px;
    margin-right: 10%;
    width: 50px;
    border-radius: 50%;

    @include breakpoint-for(desktop-small) {
      margin-right: 40px;
    }
  }
}
