@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.ModalCandidateInvite {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.active {
    display: flex;
  }

  .modal-status-preview {
    background-color: #fafbfb;
    border-radius: 9px;
    max-height: 635px;
    max-width: 643px;
    padding: 35px 30px;
    position: relative;
    width: 100%;

    h5 {
      font-size: pxToRem(25px);
      font-family: $primary_font_demi;
      color: #00016e;
      margin: 60px 30px;
    }

    .close-modal {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .x-container {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 20px;
        position: relative;
        width: 30px;

        .x-line {
          background-color: #707070;
          height: 2px;
          position: absolute;
          width: 100%;
        }

        .x-line-left {
          transform: rotate(45deg);
        }

        .x-line-right {
          transform: rotate(-45deg);
        }
      }
    }

    .box-input-create {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 300px;
      overflow-y: scroll;

      .separator {
        background-color: $text_secondary_dark_color;
        // border: none;
        color: $text_secondary_dark_color;
        display: block;
        height: 1px;
        margin: 0;
        opacity: 0.2;
        width: 100%;
      }

      div {
        display: flex;
        justify-content: space-between;
        padding: 14px 30px;
        width: 100%;

        strong {
          color: #00016e;
          font-family: $primary_font_demi;
          font-size: pxToRem(17px);
        }

        p {
          color: #00016e;
          font-family: $primary_font_book;
          font-size: pxToRem(17px);
          font-style: italic;
          opacity: 0.65;
        }
      }

      .Button {
        height: 55px;
        padding: 0;
        width: 110px;
      }
    }
  }
}
