@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.TagsLanding {
  background-color: #010230;
  height: 100%;
  padding-bottom: 100px;

  @include breakpoint-for(desktop-small) {
    padding-bottom: 200px;
  }

  h5 {
    color: $text_primary_light_color;
    font-family: $primary_font_demi;
    font-size: pxToRem(45px);
    margin: 0 0 0 20px;

    @include breakpoint-for(desktop-small) {
      margin: 0 0 0 84px;
      font-size: pxToRem(89px);
    }

    @include breakpoint-for(desktop-medium) {
      font-size: pxToRem(89px);
      line-height: 90px;
    }

    span {
      color: #010230;
      text-shadow:
        -1.4px -1.4px 0 white,
        1.4px -1.4px 0 white,
        -1.4px 1.4px 0 white,
        1.4px 1.4px 0 white;
    }
  }

  .container-tags {
    margin-top: 80px;
    overflow: hidden;

    @include breakpoint-for(desktop-small) {
      margin-top: 149px;
    }

    .list-tags {
      display: flex;
      gap: 0 20px;
      margin-bottom: 20px;
      padding-left: 20px;
      position: relative;

      &.two {
        left: -30px;
        position: relative;
      }


      @include breakpoint-for(desktop-small) {
        gap: 0 50px;
        margin-bottom: 40px;
        padding-left: 40px;

        &.two {
          left: -180px;
          position: relative;
        }
      }

      .tag {
        background: #010E63;
        border: 2px solid #010E63;
        display: flex;
        align-items: center;
        height: 71px;
        min-width: 180px;
        padding: 20px 21px;
        position: relative;

        &.active {
          background-color: transparent;
          border: 2px solid $text_primary_light_color;
        }

        img {
          height: 15px;
          position: absolute;
          right: 21px;
          width: 15px;
          top: 11px;
        }

        p {
          color: $text_primary_light_color;
          font-family: $primary_font_book;
          font-size: pxToRem(14px);
          font-weight: 500;
          margin: 0;
        }


        @include breakpoint-for(desktop-small) {
          padding: 20px 21px;
          height: 91px;
          min-width: 252px;
        }

        @include breakpoint-for(desktop-medium) {
          padding: 20px 21px;
          height: 91px;
          min-width: 252px;

          p {
            font-size: pxToRem(21px);
          }
        }
      }
    }
  }
}