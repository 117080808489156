@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';

.Button {
  align-items: center;
  background-color: $background_button_light;
  border-radius: 16px;
  border: none;
  color: $background_primary_blue_color;
  cursor: pointer;
  display: inline-flex;
  font-family: $primary_font_demi;
  font-size: pxToRem(17px);
  justify-content: center;
  padding: 18px 46px;

  &:hover {
    filter: brightness(0.9);
  }

  &.active {
    background-color: $background_primary_blue_color;
    color: $background_primary_light_color;
  }
}
