@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.CompanyProfile {
  background-color: $background_white_color;
  max-height: 100vh;
  overflow-y: scroll;
  width: 100%;

  @include breakpoint-for(desktop-extra-large-4k) {
    max-width: 1511px;
  }

  .menu-job-details {
    background-color: $background_light_grey;
    height: 170px;
    padding: 58px 125px;
    position: relative;
    z-index: 2;

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(17px);
      margin: 0;
    }

    .info {
      align-items: center;
      display: flex;
      height: 50px;

      .info-job {
        align-items: center;
        display: flex;

        .button-edit {
          margin-left: 40px;
        }

        p {
          cursor: pointer;
          margin: 0;

          &:hover {
            filter: brightness(1.3);
          }
        }

        img {
          margin: 0 30px 0 20px;
        }

        span {
          color: $background_primary_blue_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(17px);
        }
      }
    }
  }

  .container-job-user-details {
    background-color: $background_white_color;
    padding: 58px 125px;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 4;

    .container-card-table {
      gap: 20px 0;
      display: flex;
      flex-direction: column;
    }

    .images-container {
      position: relative;

      .background {
        border-radius: 20px;
        height: 248px;
        width: 100%;
      }

      .profile {
        border-radius: 50%;
        border: 3px solid #ffffff;
        bottom: -60px;
        height: 153px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 153px;
      }
    }

    .container-info {
      .info-button {
        display: flex;
        flex-direction: column;

        .first-container-info {
          display: flex;
          justify-content: center;
          margin-top: 100px;

          .title-and-info {
            .title-details {
              display: flex;
              align-items: center;

              h3 {
                color: $text_primary_dark_color;
                font-family: $primary_font_demi;
                font-size: pxToRem(30px);
                margin: 0;
              }
            }
          }
        }

        .box-description {
          background-color: $background_primary_light_color;
          border-radius: 29px;
          margin: 50px 0 50px 0;
          padding: 45px 70px;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: $primary_font_book;
            font-size: pxToRem(19px);
            margin: 0;
          }
        }
      }
    }

    .info-jobs {
      font-family: $primary_font_book;
      font-size: pxToRem(19px);
      margin: 76px 0 !important;
      text-align: center;
      width: 100%;
    }
  }
}
