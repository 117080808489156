@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.container-new-vacancies {
  padding: 58px 60px 58px 125px !important; 
}

.container-jobs {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.MyJobList {
  display: flex;
  flex-wrap: wrap;

  .JobCard {
    margin: 20px 20px 0 0;
  }

  .container-buttons {
    gap: 0 10px;
    
    .Button {
      width: 100%;

      &:first-child {
        width: 50%;
      }
    }
  }
}
