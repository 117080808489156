@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.ModalOnboarding {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.active {
    display: flex;
  }

  .modal-status-preview {
    background-color: #fafbfb;
    border-radius: 9px;
    max-width: 1089px;
    padding: 35px 15px;
    position: relative;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      padding: 35px 46px 76px 92px;
    }

    .close-modal {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0 15px;

      @include breakpoint-for(desktop-small) {
        padding: 0;
      }

      .x-container {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 15px;
        position: relative;
        width: 30px;

        .x-line {
          background-color: #707070;
          height: 2px;
          position: absolute;
          width: 100%;
        }

        .x-line-left {
          transform: rotate(45deg);
        }

        .x-line-right {
          transform: rotate(-45deg);
        }
      }
    }

    .title-modal {
      color: $text_primary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(20px);
      margin: 48px 0;
      text-align: left;

      @include breakpoint-for(desktop-small) {
        font-size: pxToRem(27px);
      }
    }

    .text-modal {
      p {
        font-family: $primary_font_book;
        font-size: pxToRem(17px);
      }

      @include breakpoint-for(desktop-small) {
        padding-right: 42px;

        p {
          font-size: pxToRem(21px);
        }
      }
    }

    .container-buttons-modal {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      margin-top: 56px;

      strong {
        font-family: $primary_font_demi;
        font-size: pxToRem(20px);
      }

      .Button {
        height: 55px;
        margin-top: 53px;
        width: 152px;
        border-radius: 9px;
      }

      @include breakpoint-for(desktop-small) {
        strong {
          font-size: pxToRem(23px);
        }

        .Button {
          border-radius: 16px;
        }
      }
    }
  }
}
