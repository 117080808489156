@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.FavoriteList {
  background-color: #f2f4f5;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 0 15px 120px 15px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    background-color: $background_white_color;
    padding: 0;
  }

  .menu-favorite {
    align-items: flex-end;
    background-color: $background_light_grey;
    display: flex;
    padding: 0;
    z-index: 2;

    @include breakpoint-for(desktop-small) {
      height: 170px;
      padding: 58px 125px;
    }

    .buttons-jobs-student {
      bottom: -20px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      position: relative;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        display: none;
      }

      div {
        display: flex;
        gap: 0 15px;
      }

      button {
        align-items: flex-start;
        background-color: #fafbfb;
        border-radius: 21px;
        border: 1px solid #fafbfb;
        color: #4d5a60;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_book;
        font-size: pxToRem(16px);
        height: 66px;
        justify-content: center;
        padding: 13px 30px;
        text-decoration: none;
        width: max-content;
      }
    }

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(22px);
      margin: 0;
    }

    .info {
      align-items: center;
      display: flex;
      height: 50px;

      &.student {
        display: none;

        p {
          font-size: pxToRem(19px);
        }

        @include breakpoint-for(desktop-small) {
          display: flex;
        }
      }

      &.sketch {
        p {
          margin-right: 26px;
        }

        .Button {
          background-color: white;
        }
      }
    }
  }

  .container-favorites {
    background-color: #fafbfb;
    border-radius: 9px;
    padding: 58px 125px;
    position: relative;
    top: -1px;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      border-radius: 0;
    }

    &.student {
      padding: 25px 11px;

      @include breakpoint-for(desktop-small) {
        background-color: $background_white_color;
        padding: 58px 52px;
      }
    }

    .favorites {
      background-color: #fafbfb;
      border-radius: 9px;

      @include breakpoint-for(desktop-small) {
        background: $background_primary_light_color;
        padding: 54px 73px;
      }

      .title-container {
        background-color: $background_light_grey;
        border-radius: 9px;
        color: $text_secondary_dark_color;
        font-family: $primary_font_demi;
        font-size: pxToRem(13px);
        padding: 16px 18px;
        text-align: center;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(19px);
          padding: 22px 51px 19px;
          text-align: initial;
        }
      }

      .box-favorites {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
        padding-bottom: 20px;

        @include breakpoint-for(desktop-small) {
          padding: 0;
        }
      }
    }
  }
}
