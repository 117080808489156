@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.Navbar {
  align-items: center;
  background-color: $background_primary_blue_color;
  bottom: 0;
  display: flex;
  height: 68px;
  position: fixed;
  width: 100%;
  z-index: 5;

  @include breakpoint-for(desktop-small) {
    align-items: center;
    background-color: $background_primary_blue_color;
    display: flex;
    flex-direction: column;
    height: 100%;
    height: 100vh;
    left: 0;
    position: initial;
    width: 86px;
  }

  &.active {
    display: none;
  }

  .logo {
    display: none;
    height: 38px;
    margin-top: 76px;
    width: 45px;

    @include breakpoint-for(desktop-small) {
      display: flex;
    }
  }

  .container-navbar {
    display: flex;
    justify-content: space-around;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 40px 0;
      margin-top: 73px;
    }
  }

  .container-ballon {
    height: 40px;
    position: relative;

    .onboarding-cloud {
      position: absolute;
      top: -20px;
      left: 319px;
      z-index: 5;

      .custom-background {
        background: #000000 0% 0% no-repeat padding-box;
        height: 100vh;
        left: 0;
        opacity: 0.42;
        position: fixed;
        top: 0;
        width: 100vw;
      }

      .ballon-cloud {
        background-color: white;
        border-radius: 30px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        height: 245px;
        padding: 10px 45px;
        position: relative;
        transform: translateX(-50%);
        width: 514px;

        @include breakpoint-for(desktop-small) {

        }

        &::before {
          border-color: transparent transparent transparent white;
          border-style: solid;
          border-width: 15px;
          content: "";
          left: -28px;
          position: absolute;
          top: 10%;
          transform: rotate(180deg);
        }

        .numbers {
          text-align: right;
          font-size: pxToRem(18px);
          font-family: $primary_font_book;
        }

        .info-ballon {
          display: flex;
          align-items: flex-start;

          div {
            margin-top: 6px;
            padding-left: 10px;

            strong {
              font-family: $primary_font_demi;
              font-size: 19px;
            }

            p {
              font-family: $primary_font_book;
              font-size: 16px;
              margin: 16px 0 0 0;
              max-height: 60px;
            }
          }
        }

        .buttons-ballon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          height: 34px;
          gap: 0 60px;

          button {
            background: #ebebeb 0% 0% no-repeat padding-box;
            border-radius: 9px;
            border: none;
            cursor: pointer;
            font-size: 15px;
            height: 34px;
            width: 88px;
          }
        }
      }
    }
  }
}
