@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Loading {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 20;

  &.visible {
    display: flex;
  }

  & > div {
    align-items: center;
    background-image: url("../../../assets/images/loading/loading_bg.png");
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: center;
    width: 100%;

    & > div {
      height: 100px;
      margin-top: 48px;
      position: relative;
      width: 115px;

      div {
        animation: profile 1s infinite;
        box-sizing: border-box;
        background-color: transparent;
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
        border-bottom: 25px solid $background_white_color;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        right: 50px;
        top: 50px;
        transform-origin: top center;
        width: 5px;

        @include breakpoint-for(tablet){
          border-bottom-width: 40px;
          border-left-width: 7px;
          border-right-width: 7px;
        }

        @for $n from 1 to 21 {
          &:nth-child(#{$n}) {
            animation-delay: $n * 0.05s;
            transform: rotate($n * 18deg) translateY(8px);

            @include breakpoint-for(tablet) {
              transform: rotate($n * 18deg) translateY(10px);
            }
          }
        }
      }
    }

    p {
      color: $background_white_color;
      font-size: 20px;
      font-weight: 200;
      margin-top: 20px;
      text-align: center;
      text-transform: lowercase;

      @include breakpoint-for(tablet) {
        font-size: 30px;
        margin: 40px 0 0;
      }
    }
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes profile {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
