@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.JobFinished {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;

  .JobCard {
    .Button {
      width: 100%;
    }
  }
}
