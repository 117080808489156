@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.Onboarding {
  background-color: $background_white_color;
  max-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 70px;
  width: 100%;

  .menu-onboarding {
    background-color: $background_light_grey;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    @include breakpoint-for(desktop-small) {
      display: block;
      height: 170px;
      padding: 58px 125px;
    }

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(22px);
      margin: 0;
    }

    .info {
      align-items: center;
      display: flex;
      height: 50px;

      &.student {
        p {
          font-size: pxToRem(19px);
        }
      }

      &.sketch {
        p {
          margin-right: 26px;
        }

        .Button {
          background-color: white;
        }
      }
    }
  }

  .container-onboarding {
    background-color: $background_white_color;
    padding: 22px 15px;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 4;

    @include breakpoint-for(desktop-small) {
      padding: 58px 50px 0 50px;
    }

    .container-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      max-height: 200px;
      margin-top: 56px;
      width: 100%;
      overflow-y: scroll;

      @include breakpoint-for(desktop-large) {
        overflow-y: initial;
        gap: 9px;
      }
    }

    .container-cards {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      justify-content: center;
      margin-top: 56px;
      padding-left: 40px;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        gap: 20px;
        justify-content: flex-start;
      }

      &.projects {
        justify-content: center;
        gap: 20px 34px;
        padding: 0;

        @include breakpoint-for(desktop-small) {
          padding-left: 40px;
          justify-content: flex-start;
        }
      }

      .list-favorite {
        background-color: white;
        border-radius: 16px;
        box-shadow: 0px 3px 11px #00000005;
        display: block;
        height: 273px;
        padding: 31px 24px;
        width: 230px;

        .info-list {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;

          div {
            margin-top: 5px;
            
            p {
              color: $background_primary_blue_color;
              font-family: $primary_font_demi;
              font-size: pxToRem(19px);
              margin: 0 0 31px 0;
            }

            hr {
              background: #00016e 0% 0% no-repeat padding-box;
              border-radius: 5px;
              height: 5px;
              margin: 8px 0;
              opacity: 0.11;
              width: 65px;
            }

            span {
              color: $background_primary_blue_color;
              display: block;
              font-family: $primary_font_demi;
              font-size: pxToRem(19px);
              margin: 25px 0;
            }
          }

          .Input {
            width: 90%;
            height: 30px;
          }

          .Button {
            font-size: pxToRem(19px);
            height: 55px;
            padding: 0;
            width: 100%;
          }
        }
      }

      .certificate-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 160px;
        height: 160px;
        background-image: url("../../assets/images/editor-profile/module-background.svg");
        padding-top: 40px;
        background-size: contain;
        background-repeat: no-repeat;

        @include breakpoint-for(desktop-small) {
          width: 223px;
          height: 249px;
        }

        span {
          color: $background_white_color;
          font-size: pxToRem(16px);
          font-family: $primary_font_demi;
          padding: 0 32px 0 9px;

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(28px);
            padding: 0;
          }
        }

        p {
          color: $background_white_color;
          text-align: justify;
          font-size: pxToRem(9px);
          font-family: $primary_font_book;
          padding: 0 32px 0 9px;

          @include breakpoint-for(desktop-small) {
            max-width: 156px;
            font-size: pxToRem(12px);
            padding: 0;
          }
        }

        .number-relative {
          width: 30px;
          height: 30px;
          position: relative;
          top: 5px;
          left: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: $background_white_color;
          background-color: #01c7f0;
          font-size: pxToRem(19px);
          font-family: $primary_font_demi;

          @include breakpoint-for(desktop-small) {
            top: 26px;
            left: 55px;
            font-size: pxToRem(19px);
          }
        }
      }
    }

    .box-input-create {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 30px;
      border: 1px solid #e8e8e8;
      border-radius: 4px;

      .container-image {
        position: relative;

        .cover {
          border-radius: 20px;
          height: 248px;
          width: 100%;
        }
      }

      .box-image {
        width: 100%;
      }

      .container-profile {
        justify-content: center;
        display: flex;
      }

      .profile-image {
        border-radius: 50%;
        height: 150px;
        width: 150px;

        .pincel {
          div {
            height: 30px;
            width: 30px;

            img {
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      .pincel {
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_demi;
        font-size: pxToRem(17px);
        justify-content: center;
        width: 100%;

        &.relative {
          width: min-content;
          position: absolute;
          top: 5%;
          right: 5%;
        }

        div {
          align-items: center;
          background-color: $background_white_color;
          display: flex;
          height: 50px;
          justify-content: center;
          width: 50px;
          border-radius: 50%;

          img {
            height: 30px;
            width: 30px;
          }
        }
      }

      .Input {
        width: 100%;

        svg {
          bottom: 37%;
        }

        label {
          color: $text_primary_dark_color;
          font-family: $primary_font_demi;
          font-weight: 500;
          font-size: pxToRem(17px);
        }

        input {
          border: 1px solid #e8e8e8;
          height: 52px;
        }
      }

      .container-buttons-modal {
        display: flex;
        gap: 0 12px;
        justify-content: flex-end;
        width: 100%;
      }
    }

    &:last-child {
      padding-bottom: 40px;
    }

    .onboardings {
      background: $background_primary_light_color;
      border-radius: 9px;
      padding: 54px 15px;

      @include breakpoint-for(desktop-small) {
        padding: 54px 73px;
      }

      .container-buttons {
        display: flex;
        justify-content: space-between;
        max-width: 631px;
      }

      .title-container {
        background-color: $background_light_grey;
        border-radius: 9px;
        color: $text_secondary_dark_color;
        font-family: $primary_font_demi;
        font-size: pxToRem(19px);
        padding: 22px 51px 19px;
        width: 100%;
      }

      .container-inputs {
        margin-top: 50px;
        padding-bottom: 30px;

        @include breakpoint-for(desktop-small) {
          margin-left: 50px;
        }

        .Input,
        .Select {
          max-width: 631px;
        }

        .Select {
          margin-bottom: 25px;

          select {
            background-color: $background_white_color;
            font-size: pxToRem(15px);
          }
        }

        .container-text {
          .error-message {
            color: red;
          }

          .title {
            display: flex;
            margin: 40px 0 10px 0;
          }

          .quill {
            border-radius: 4px !important;
            height: 204px !important;

            &.error-message {
              .ql-toolbar,
              .ql-container {
                border: 1px solid red !important;
              }
            }
          }

          .ql-toolbar,
          .ql-container {
            background-color: $background_white_color;
            border-radius: 4px 4px 0 0 !important;
            border: 1px solid #e8e8e8 !important;
            font-family: $primary_font_book;
            font-size: pxToRem(17px);
            max-width: 631px;

            button {
              height: 35px;
              width: 35px;
            }
          }

          .ql-container {
            border-radius: 0 0 4px 4px !important;
            border-top: none !important;
          }
        }
      }
    }
  }

  .container-info-sensitives {
    background-color: $background_white_color;
    padding: 58px 15px 10px 15px;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 4;

    @include breakpoint-for(desktop-small) {
      padding: 58px 102px 0 170px;
    }

    &.left {
      display: flex;
      justify-content: flex-end;
      padding: 0 15px 15px 15px;

      @include breakpoint-for(desktop-small) {
        padding: 58px 102px 0 170px;
      }
    }

    span {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(19px);
    }

    .Input,
    .Select {
      margin-top: 51px;
      max-width: 631px;
    }

    .Button {
      font-family: $primary_font_demi;
      font-size: pxToRem(17px);
      font-weight: 500;
      padding: 18px 20px;
    }
  }
}

.success-modal {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;

  .info-modal {
    align-items: center;
    background: #fafbfb 0% 0% no-repeat padding-box;
    border-radius: 9px;
    display: flex;
    height: 148px;
    justify-content: center;
    opacity: 1;
    width: 523px;

    p {
      font-family: $primary_font_demi;
      font-size: pxToRem(24px);
      margin-right: 16px;
    }

    img {
      height: 45px;
      width: 45px;
    }
  }
}
