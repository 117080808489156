@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.StudentProcessDetails {
  background-color: $background_white_color;
  max-height: 100vh;
  overflow-y: scroll;
  width: 100%;

  @include breakpoint-for(desktop-extra-large-4k) {
    max-width: 1511px;
  }

  .menu-job-details {
    background-color: $background_light_grey;
    height: 170px;
    padding: 58px 125px;
    position: relative;
    z-index: 2;

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(17px);
      margin: 0;
    }

    .info {
      align-items: center;
      display: flex;
      height: 50px;

      .info-job {
        align-items: center;
        display: flex;

        p {
          cursor: pointer;
          margin: 0;

          &:hover {
            filter: brightness(1.3);
          }
        }

        img {
          margin: 0 30px 0 20px;
        }

        span {
          color: $background_primary_blue_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(17px);
        }
      }
    }
  }

  .container-status-process {
    display: flex;
    padding: 42px 52px;
    width: 100%;

    .status-preview {
      background-color: #FAFBFB;
      border-radius: 9px;
      display: flex;
      flex-direction: column;
      height: max-content;
      justify-content: flex-start;
      padding: 35px 78px;
      position: relative;
      width: 100%;
  
      .container-status {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: space-between;
        margin-top: 38px;
        width: 100%;
  
        .info-user {
          max-width: 457px;
          width: 100%;
  
          span {
            color: $text_primary_dark_color;
            font-family: $primary_font_demi;
            font-size: pxToRem(26px);
          }
  
          .company-info {
            align-items: center;
            display: flex;
            margin: 10px 0 70px;
  
            img {
              border-radius: 7px;
              height: 46px;
              margin-right: 12px;
              width: 46px;
            }
  
            p {
              color: $text_primary_dark_color;
              font-family: $primary_font_book;
              font-size: pxToRem(21px);
              margin: 0;
            }
  
          }
  
  
          .person-info {
            display: flex;
            gap: 0 58px;
  
            .person {
              display: flex;
              flex-direction: column;
              gap: 9px 0;
  
              .box-person {
                align-items: center;
                display: flex;
  
                img {
                  height: 17px;
                  margin-right: 7px;
                  width: 17px;
                }
  
                p {
                  font-family: $primary_font_book;
                  font-size: pxToRem(14px);
                  margin: 4px 0;
                }
  
                .inative {
                  display: flex;
  
                  span {
                    background: #E9E9E9 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    display: inline-block;
                    height: 17px;
                    width: 120px;
                  }
                }
              }
            }
          }
  
          .description-person {
            margin-top: 25px;
            width: 100%;
  
            .title-description {
              border-radius: 9px;
              color: $text_primary_dark_color;
              display: flex;
              font-family: $primary_font_demi;
              font-size: pxToRem(21px);
              padding: 9px 0;
              width: 100%;
            }
  
            .description-text {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 10;
              display: -webkit-box;
              margin-top: 20px;
              overflow: hidden;
              padding: 0;
            }
  
            .description-text::-webkit-scrollbar {
              height: 3px;
              width: 3px;
            }
  
            .description-text::-webkit-scrollbar-track {
              background: #4d5a6075;
              border-radius: 10px !important;
            }
  
            .description-text::-webkit-scrollbar-thumb {
              background: #4D5A60;
              border: none;
            }
          }
        }
  
        .division {
          display: inline-block;
          min-width: 77px;
        }
  
        .status-candidate {
          border-radius: 9px;
          max-width: 921px;
          padding: 36px 26px;
          width: 100%;
  
          .title-status-candidate {
            align-items: center;
            display: flex;
            margin-left: 10px;
  
            .image-container {
              align-items: center;
              background-color: white;
              border-radius: 9px;
              display: flex;
              height: 71px;
              justify-content: center;
              width: 69px;
  
              img {
                height: 25px;
                width: 40px;
              }
            }
  
            span {
              font-family: $primary_font_demi;
              font-size: pxToRem(19px);
              margin-left: 14px;
            }
  
          }
  
          .description-status {
            font-family: $primary_font_book;
            font-size: pxToRem(17px);
            margin: 40px 0 26px 23px;
          }
  
          .container-status-person {
            display: flex;
            gap: 20px 0;
            position: relative;
  
            .box-person-container {
              align-items: center;
              display: flex;
              flex-direction: column;
              flex: 1;
              gap: 20px;
            }
  
            .timeline {
              align-items: center;
              display: flex;
              flex-direction: column;
              gap: 20px;
              left: -80px;
              position: absolute;
  
              .timeline-item {
                align-items: center;
                display: flex;
                height: 99px;
  
                &:last-child {
                  ::before {
                    height: 0;
                  }
                }
              }
  
              .number {
                align-items: center;
                background-color: #00016E;
                border-radius: 50%;
                color: #fff;
                display: flex;
                font-family: $primary_font_demi;
                font-size: pxToRem(15px);
                height: 24px;
                justify-content: center;
                position: relative;
                width: 24px;
  
                &.inative {
                  background-color: #C0C6C8;
                }
              }
  
              .number::before {
                background-color: #C0C6C8;
                content: "";
                height: 100px;
                left: 50%;
                position: absolute;
                top: 100%;
                transform: translateX(-50%);
                width: .2px;
              }
            }
  
            .box-status {
              align-items: center;
              background-color: $background_white_color;
              border-radius: 20px;
              border: 1px solid #EAEDEF;
              box-shadow: 0px 3px 11px #00000005;
              display: flex;
              height: 99px;
              padding: 27px 25px;
              width: 100%;
  
              div {
                display: flex;
                flex-direction: column;
  
                p {
                  margin: 0;
                }
  
                span {
                  color: #00016E;
                  font-family: $primary_font_book;
                  font-size: pxToRem(15px);
                  font-style: italic;
                  margin-top: 9px;
                }
              }
  
              &.inative {
                opacity: 0.61;
  
                img {
                  height: 40px;
                  width: 40px;
                }
  
                p {
                  color: #4D5A60;
                }
              }
  
              img {
                height: 45px;
                margin-right: 23px;
                width: 45px;
              }
  
              p {
                color: $background_primary_blue_color;
                font-family: $primary_font_demi;
                font-size: pxToRem(19px);
                margin-right: 23px;
              }
  
              button {
                background: #EBEBF4;
                border-radius: 15px;
                border: 1px solid #EBEBF4;
                color: $background_primary_blue_color;
                cursor: pointer;
                font-family: $primary_font_demi;
                font-size: pxToRem(15px);
                height: 54px;
                padding: 0 21px;
  
                &:hover {
                  background-color: transparent;
                  border: 1px solid #00016E;
                }
  
                &.active {
                  background-color: transparent;
                  border: 1px solid #00016E;
  
                  &:hover {
                    border: 1px solid #EBEBF4;
                    background: #EBEBF4;
                    filter: brightness(1);
                  }
                }
              }
  
              .two-buttons {
                display: flex;
                gap: 0 15px;
                height: 54px;
                justify-content: flex-end;
                margin-left: 30px;
              }
            }
          }
        }
      }
    }
  }
}