@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.SearchTag {
  align-items: center;
  background-color: $background_primary_light_color;
  border-radius: 29px;
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 60px 30px 30px 60px;

  .title {
    align-items: center;
    color: $text_secondary_dark_color;
    display: flex;
    height: 10px;
    justify-content: flex-start;
    position: relative;
    width: 100%;

    .arrow {
      border-width: 0 0 1px 1px;
      border: solid transparent;
      bottom: 0;
      box-shadow: 1px -1px 0 1px $text_secondary_dark_color inset;
      left: 0;
      padding: 12px;
      position: absolute;
      transform: rotate(-45deg);
    }

    p {
      font-family: $primary_font_demi;
      font-size: pxToRem(20px);
      margin: 0 0 0 17px;
    }
  }

  .container-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    max-height: 200px;
    margin-top: 56px;
    overflow-y: scroll;
    width: 100%;
    
    @include breakpoint-for(desktop-large) {
      gap: 9px;
    }
  }

  .Button {
    margin-top: 47px;
    width: 160px;
  }
}
