@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/function";
@import "../../../../assets/scss/mixins";

.ModalSignUp {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  padding: 0 15px;
  top: 0;
  width: 100%;
  z-index: 10;

  &.active {
    display: flex;
  }

  .modal-status-preview {
    background-color: #03067d;
    border-radius: 9px;
    max-width: 1089px;
    padding: 45px 20px;
    position: relative;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      padding: 45px 45px 45px 90px;
    }

    .close-modal {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .x-container {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 15px;
        position: relative;
        width: 30px;

        .x-line {
          background-color: #707070;
          height: 2px;
          position: absolute;
          width: 100%;
        }

        .x-line-left {
          transform: rotate(45deg);
        }

        .x-line-right {
          transform: rotate(-45deg);
        }
      }
    }

    .title-modal {
      color: #01c7f0;
      font-family: $primary_font_demi;
      font-size: pxToRem(27px);
      margin: 48px 0;
      text-align: left;
    }

    .text-modal {
      padding-right: 42px;
      color: white;

      p {
        font-family: $primary_font_book;
        font-size: pxToRem(21px);
        font-weight: 300;
      }

      strong {
        font-family: $primary_font_demi;
      }
    }

    .container-buttons-modal {
      align-items: flex-end;
      display: flex;
      flex-direction: column;

      strong {
        font-family: $primary_font_demi;
        font-size: pxToRem(23px);
      }

      .Button {
        height: 55px;
        margin-top: 53px;
        background-color: #0006ff;
        color: white;
        border-radius: 0;
      }
    }
  }
}
