@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.DetailListFavorite {
  .list-favorites {
    background-color: $background_primary_light_color;
    display: flex;
    flex-direction: column;
    min-height: 575px;
    padding: 52px 60px;

    .title-container {
      align-items: center;
      background-color: $background_light_grey;
      border-radius: 9px;
      display: flex;
      height: 64px;
      padding: 0 51px;

      span {
        color: text_secondary_dark_color;
        cursor: pointer;
        font-family: $primary_font_demi;
        font-size: pxToRem(19px);

        &:hover {
          opacity: 0.6;
        }
      }

      img {
        margin: 29px;
      }

      p {
        color: $background_primary_blue_color;
        font-family: $primary_font_demi;
        font-size: pxToRem(19px);
      }
    }

    .button-container {
      margin: 51px 0 21px 0;
    }


    .container-details {
      .PersonJobCard {
        background-color: white;

        .Button {
          font-size: 14px;
          height: 55px;
          padding: 18px 17px;
        }

        .container-details {
          height: max-content;
          width: max-content;

          hr {
            width: 90%;
          }

          button {
            font-size: 17px;
            justify-content: flex-start;
            padding: 0 20px;
          }
        }
      }
    }
  }
}
