@import '../../../assets/scss/_variables';
@import '../../../assets/scss/_mixins';
@import '../../../assets/scss/_function';

$md-radio-checked-color: black;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

@keyframes ripple {

  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }

  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.Radio {
  margin: 16px 0;

  p {
    color: $text_primary_dark_color;
    font-family: $primary_font_book;
    font-size: pxToRem(15px);
    font-weight: 500;
    margin-top: 5px !important;
    padding: 0 ($md-radio-size + 10px);
  }

  input[type="radio"] {
    display: none;

    &:checked+label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked+label:after {
      transform: scale(1);
    }
  }

  label {
    color: $text_primary_dark_color;
    cursor: pointer;
    display: inline-block;
    font-family: $primary_font_book;
    font-size: pxToRem(18px);
    font-weight: 700;
    height: $md-radio-size;
    margin-bottom: 0;
    padding: 0 ($md-radio-size + 10px);
    position: relative;
    vertical-align: bottom;

    &:before,
    &:after {
      border-radius: 50%;
      content: '';
      position: absolute;
      transition-property: transform, border-color;
      transition: all .3s ease;
    }

    &:before {
      border: 2px solid $md-radio-border-color;
      height: $md-radio-size;
      left: 0;
      top: 0;
      width: $md-radio-size;
    }

    &:after {
      background: $md-radio-checked-color;
      height: $md-radio-checked-size + 2;
      left: $md-radio-size / 2 - $md-radio-checked-size / 2;
      top: $md-radio-size / 2 - $md-radio-checked-size / 2;
      transform: scale(0);
      width: $md-radio-checked-size + 2;
    }
  }
}