@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.Recommendation {
  padding: 60px 30px 30px 60px;
  align-items: center;
  background-color: $background_primary_light_color;
  border-radius: 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;

  .title {
    align-items: center;
    color: $text_secondary_dark_color;
    display: flex;
    height: 10px;
    justify-content: flex-start;
    position: relative;
    width: 100%;

    .arrow {
      border-width: 0 0 1px 1px;
      border: solid transparent;
      bottom: 0;
      box-shadow: 1px -1px 0 1px $text_secondary_dark_color inset;
      left: 0;
      padding: 12px;
      position: absolute;
      transform: rotate(-45deg);
    }

    p {
      font-family: $primary_font_demi;
      font-size: pxToRem(20px);
      margin: 0 0 0 17px;
    }
  }

  .container-persons {
    display: flex;
    flex-wrap: wrap;
    gap: 48px 20px;
    height: 100%;
    justify-content: flex-start;
    margin-top: 65px;
    width: 100%;
  }
}
