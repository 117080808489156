@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.StudentProcess {
  background-color: $background_white_color;
  border-radius: 60px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    border-radius: 0;
  }

  .container-inprogress {
    background: $background_primary_light_color;
    border-radius: 9px;
    padding: 25px 11px;

    @include breakpoint-for(desktop-small) {
      padding: 54px 73px;
    }

    .title-container {
      background-color: $background_light_grey;
      border-radius: 9px;
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(13px);
      padding: 16px 18px;
      text-align: center;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        font-size: pxToRem(19px);
        padding: 22px 51px 19px;
        text-align: initial;
      }
    }

    .list-process-student {
      display: flex;
      flex-wrap: wrap;
      gap: 19px 10px;
      margin-top: 31px;
      padding-bottom: 120px;

      @include breakpoint-for(desktop-small) {
        gap: 10px;
      }

      .empty-title {
        margin: 50px 0;
        text-align: center;
        width: 100%;
      }

      .JobRecommendation {
        .container-buttons {
          gap: 10px;

          .Button {
            &:first-child {
              background-color: $background_white_color;
              width: 45%;
            }
          }
        }
      }
    }
  }
}
