@import "../../../assets/scss/_variables";
@import "../../../assets/scss/_mixins";
@import "../../../assets/scss/_function";

.NavigationLink {
  height: 38px;
  text-decoration: none;
  width: 38px;

  &.disable {
    cursor: not-allowed;

    img {
      opacity: 0.4;
    }
  }

  &.active {
    img {
      opacity: 1;
    }
  }

  img {
    height: 30px;
    width: 30px;
    opacity: 0.5;

    @include breakpoint-for(desktop-small) {
      opacity: 1;
      height: 38px;
      width: 38px;
    }
  }

  &:not([data-label=""]) {
    &:hover {
      &::before {
        opacity: 1;
        transition: opacity 0.4s;
        visibility: visible;
      }

      &::after {
        opacity: 1;
        transition: opacity 0.4s;
        visibility: visible;
      }
    }
  }

  &::after {
    background-color: $background_secondary_blue_color;
    border-radius: 19px;
    content: attr(data-label);
    display: none;
    font-family: $primary_font_book;
    font-size: pxToRem(18px);
    padding: 8px 23px;
    white-space: nowrap;
    width: auto;
    z-index: 10;

    @include breakpoint-for(desktop-small) {
      display: initial;
    }
  }

  &::after,
  &::before {
    color: $background_primary_blue_color;
    left: 70px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
  }
}
