@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.ModalPassword {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.active {
    display: flex;
  }

  .modal-status-preview {
    background-color: #FAFBFB;
    border-radius: 9px;
    max-width: 496px;
    padding: 35px 30px 5px 30px;
    position: relative;
    width: 100%;

    .close-modal {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .x-container {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 15px;
        position: relative;
        width: 20px;

        .x-line {
          background-color: #707070;
          height: 2px;
          position: absolute;
          width: 100%;
        }

        .x-line-left {
          transform: rotate(45deg);
        }

        .x-line-right {
          transform: rotate(-45deg);
        }
      }
    }

    .update-password {
      font-family: $primary_font_demi;
      font-size: pxToRem(21px);
      color: $text_secondary_dark_color;
      text-align: center;
    }

    .box-input-create {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 30px;

      form {
        width: 100%;
      }

      .alert-password {
        color: red;
      }

      .Input {
        width: 100%;

        svg {
          bottom: 37%;
        }

        label {
          color: $text_primary_dark_color;
          font-family: $primary_font_demi;
          font-weight: 500;
          font-size: pxToRem(17px);
        }

        input {
          border: 1px solid #E8E8E8;
          height: 52px;
        }
      }

      .container-buttons-modal {
        display: flex;
        gap: 0 12px;
        justify-content: flex-end;
        width: 100%;
      }

      .Button {
        height: 55px;
        padding: 0;
        width: 129px;
      }
    }
  }
}