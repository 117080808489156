@import "../../../assets/scss/_variables";
@import "../../../assets/scss/_mixins";
@import "../../../assets/scss/_function";

.Select {
  display: flex;
  flex-direction: column;

  & > p {
    color: red;
    font-size: 0.7rem;
    margin: 0 0 5px 0;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > label {
      font-family: $primary_font_book;
      font-size: pxToRem(15px);
      font-weight: 500;
      margin-bottom: 7px;
    }

    & > .feedback-message {
      color: red;
      font-family: $primary_font_book;
      font-size: pxToRem(14px);
      margin: 5px 5px 0 0;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }

    & > select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      background-image: url("../../../assets/images/new-vacancies/inverted-triangle.svg");
      background-position: calc(100% - 15px) center;
      background-repeat: no-repeat;
      background-size: 10px;
      border-radius: 4px;
      border: 1px solid #e8e8e8;
      cursor: pointer;
      font-family: $primary_font_book;
      font-size: pxToRem(18px);
      font-weight: 700;
      height: 47px;
      outline: none;
      padding: 0 25px 0 10px;

      &.invalid {
        border-color: red;
        color: red;

        &::placeholder {
          color: red;
        }
      }

      &.disabled {
        background-color: rgba(198, 198, 198, 0.26);
        border-color: transparent;
        color: #6c6c6c;
        cursor: default;
      }

      & > option {
        color: black;
        cursor: pointer;
      }
    }
  }

  &.label-inline {
    & > div {
      align-items: flex-end;
      flex-direction: row;
    }
  }
}
