@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.TermsOfUse {
  font-family: $primary_font_book;
  padding: 50px 15px;

  @include breakpoint-for(desktop-small) {
    padding: 50px;
  }

  .return-button {
    position: fixed;
    left: 75%;
    top: 20px;

    button {
      background-color: #0006FF;
      border: none;
      color: white;
      font-family: $primary_font_demi;
      font-size: 15px;
      padding: 10px;
      border-radius: 9px;
    }

    @include breakpoint-for(desktop-small) {
      left: 90%;
    }
  }
}
