@import '../../../assets/scss/_variables';
@import '../../../assets/scss/_mixins';
@import '../../../assets/scss/_function';

.Input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  & > svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  p {
    opacity: 0;

    &.feedback-message {
      color: red;
      font-family: $primary_font_book;
      font-size: pxToRem(14px);
      margin: 5px 5px 0 0;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-family: $primary_font_book;
      font-size: pxToRem(15px);
      font-weight: 500;
      margin-bottom: 7px;
    }

    input {
      background-color: $background_white_color;
      border-radius: 4px;
      border: 1px solid #E8E8E8;
      color: black;
      font-family: $primary_font_book;
      font-size: pxToRem(18px);
      height: 47px;
      outline: unset;
      padding-left: 10px;

      &::placeholder {
        color: #222222;
        font-family: $primary_font_book;
        font-size: pxToRem(18px);
      }

      &.show-error {
        border-color: red;
        color: red;

        &::placeholder {
          color: red;
        }
      }

      &:disabled {
        border-color: transparent;
      }
    }
  }

  svg {
    bottom: 45%;
    position: absolute;
    right: 15px;
    width: 20px;
  }
}
