@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.InvitationsReceivedStudent {
  background-color: $background_white_color;
  width: 100%;

  .container-inprogress {
    background: $background_primary_light_color;
    border-radius: 9px;
    padding: 54px 73px;

    .title-container {
      background-color: $background_light_grey;
      border-radius: 9px;
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(19px);
      padding: 22px 51px 19px;
      width: 100%;
    }

    .container-invites {
      width: 100%;

      .container-table-inprogress {
        width: 100%;

        ol.collection {
          margin: 0px;
          padding: 0px;
        }

        li {
          list-style: none;
        }

        * {
          box-sizing: border-box;
        }

        @media screen and (max-width: 736px) {
          .collection-container {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: 1fr 1fr;
          }

          .item-container {
            &.coursed {
              background-color: #03067d;
            }
          }

          img {
            height: 77px;
            width: 77px;
          }

          strong {
            width: 100%;
          }

          .item {
            border-radius: 29px;
            border: 3px solid #03067d;
            padding: 10px;
            word-wrap: break-word;
          }

          .collection-container > li:first-child {
            display: none;
          }

          .step-course {
            align-items: center;
            display: flex;
            height: 135px;
            justify-content: center;
            width: 100%;

            &:nth-child(2) {
              margin-top: 31px;
            }

            div {
              align-items: center;
              background: #03067d;
              border-radius: 16px;
              display: flex;
              flex-direction: column;
              height: 135px;
              justify-content: center;
              width: 128px;

              img {
                height: 57px;
                width: 57px;
              }

              p {
                font-size: 13px;
              }
            }
          }

          .two-infos {
            display: flex;
            flex-direction: column;
            gap: 20px 0;
            height: max-content;
            justify-content: space-around;
          }

          .attribute::before {
            align-items: center;
            background-color: #03067d;
            border-radius: 29px;
            border: 3px solid #03067d;
            content: attr(data-name);
            display: flex;
            font-size: 14px;
            justify-content: center;
            margin: 30px 0;
            padding: 10px 0;
            text-align: center;
            width: 100%;
          }

          .attribute {
            align-items: center;
            grid-template-columns: minmax(9em, 30%) 1fr;
            margin: 30px 0;
            text-align: center;
          }
        }

        @media screen and (max-width: 580px) {
          .collection-container {
            display: grid;
            grid-template-columns: 1fr;
          }
        }

        @media screen and (min-width: 737px) {
          .item-container,
          .border-item {
            display: grid;
            grid-template-columns: minmax(304px, 1fr) minmax(0, 9fr) minmax(
                105px,
                0.5fr
              );
            margin-top: 13px;
            max-height: 109px;
            position: relative;
            width: 100%;

            &.coursed {
              background-color: #03067d;

              .coursed-text {
                align-items: center;
                display: flex;
                position: absolute;
                right: -151px;
                top: 50%;
                transform: translate(-50%, -50%);

                span {
                  font-size: 50px;
                  margin: 0 20px 0 0;
                }

                p {
                  font-family: $primary_font_book;
                  font-size: 15px;
                  margin: 0;
                }
              }
            }
          }

          .last-container {
            border: none !important;
          }

          .attribute-container {
            border-top: 1px solid #eaedef;
            box-shadow: 0px 3px 11px #00000005;
            display: grid;
            height: 100% !important;
            padding: 20px 5px;

            &:nth-child(1) {
              border-bottom: 1px solid #eaedef;
              border-left: 1px solid #eaedef;
              border-radius: 29px 0 0 29px;
            }

            &:nth-child(2) {
              border-bottom: 1px solid #eaedef;
              border-radius: 0 29px 29px 0;
              border-right: 1px solid #eaedef;

              p {
                font-size: 17px;
              }
            }

            &:nth-child(2) {
              border-bottom: 1px solid #eaedef;
              display: flex;
            }

            &:last-child {
              .attribute {
                padding: 0 !important;
              }
            }

            .two-infos {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: space-around;
            }
          }

          .attribute {
            align-items: center;
            display: flex;
            font-family: $primary_font_book;
            font-size: 21px;
            justify-content: flex-start;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;

            .status {
              align-items: center;
              border-radius: 15px;
              border: 1px solid #c5c5c5;
              display: flex;
              height: 54px;
              max-width: 256px;
              min-width: 256px;
              padding: 5px 17px;

              div {
                max-width: 256px;
                overflow: hidden;

                p {
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  color: $text_primary_dark_color;
                  display: -webkit-box;
                  font-family: $primary_font_demi;
                  font-size: pxToRem(13px);
                  line-height: 1.2;
                  max-height: 3.6em;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                }
              }

              .logo-job {
                height: 25px;
                margin-right: 9px;
                width: 44px;
              }
            }

            .status-button {
              background: #fafbfb;
              border-radius: 15px;
              border: 1px solid #c5c5c5;
              cursor: pointer;
              height: 54px;
              display: flex;
              align-items: center;
              padding: 5px 15px;
              width: 256px;

              img {
                border-radius: 5px;
                height: 36px;
                margin-right: 6px;
                width: 34px;
              }

              p {
                font-family: $primary_font_demi;
                font-size: 13px;
                width: 256px;
                white-space: break-spaces !important;
              }
            }

            .decline-candidate {
              align-items: center;
              background: #fafbfb 0% 0% no-repeat padding-box;
              border-radius: 20px;
              border: 1px solid #eaedef;
              box-shadow: 0px 3px 11px #00000005;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              gap: 9px 0;
              height: 100%;
              justify-content: center;
              margin-left: 9px;
              padding-top: 10px;
              width: 104px;

              .x-container {
                height: 20px;
                position: relative;
                width: 30px;
              }

              .x-line {
                background-color: red;
                height: 3px;
                position: absolute;
                width: 100%;
              }

              .x-line-left {
                transform: rotate(45deg);
              }

              .x-line-right {
                transform: rotate(-45deg);
              }

              p {
                font-size: 13px;
              }
            }

            .info-candidate {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 95%;

              .title-candidate {
                p {
                  font-family: $primary_font_book;
                  font-size: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap; 
                  width: 200px;

                  strong {
                    font-size: 21px;
                    font-family: $primary_font_demi;
                  }
                }
              }

              .tags-candidate {
                display: none;
                flex-wrap: wrap;
                gap: 6px;
                width: 228px;
                height: 70px;
                // width: 400px;

                @include breakpoint-for(desktop-medium) {
                  display: flex;
                }

                .tag {
                  align-items: center;
                  background-color: white;
                  border-radius: 30px;
                  border: 1px solid #eaedef;
                  color: $text_primary_dark_color;
                  display: flex;
                  font-family: $primary_font_book;
                  height: 29px;
                  margin: 0;
                  outline: none;
                  padding: 7px 10px;
                  width: max-content;

                  p {
                    font-size: pxToRem(11px) !important;
                    margin: 0;
                  }
                }
              }

              .button-candidate {
                background-color: $background_primary_blue_color;
                border-radius: 15px;
                color: $text_primary_light_color;
                cursor: pointer;
                font-family: $primary_font_demi;
                font-size: 15px;
                height: 54px;
                width: 174px;
              }

              .save-candidate {
                background-color: transparent;
                border: none;
                cursor: pointer;

                img {
                  height: 49px;
                  width: 49px;
                }
              }
            }

            .container-info {
              display: flex;
              gap: 0 23px;

              &:nth-child(2) {
                margin-top: 31px;
              }

              img {
                height: 123px;
                width: 124px;
              }

              .info-couse {
                p {
                  color: white;
                  font-family: $primary_font_book;
                  font-size: 17px;
                }
              }
            }

            .step-course {
              align-items: center;
              display: flex;
              height: 135px;
              justify-content: center;
              width: 100%;

              &:nth-child(2) {
                margin-top: 31px;
              }

              div {
                align-items: center;
                background: #03067d;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                height: 135px;
                justify-content: center;
                width: 170px;

                img {
                  height: 57px;
                  width: 57px;
                }

                p {
                  font-size: 13px;
                }
              }
            }
          }

          .collection-container > .item-container:first-child {
            border-radius: 18px;
            border: none !important;
            height: 20px;
            margin: 25px 0 5px;
            text-align: left;
          }

          .collection-container > .item-container:first-child .attribute {
            align-items: center;
            border: none;
            display: flex;
            font-size: 13px;
            justify-content: flex-start;
            overflow: auto;
            padding: 0 25px;
            text-overflow: initial;
            white-space: normal;

            &:nth-child(1) {
              margin-left: 18px;
            }
          }
        }
      }
    }
  }
}
