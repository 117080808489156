@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.JobRecommendation {
  background-color: $background_white_color;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  min-height: 386px;
  position: relative;
  max-width: 364px;

  @include breakpoint-for(desktop-small) {
    width: 364px;
  }

  .switch-button {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    z-index: 2;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 10px;
      margin: 20px 20px 0 0;
      outline: none;
      position: absolute;

      img {
        height: 43px;
        width: 43px;

        @include breakpoint-for(desktop-small) {
          height: 49px;
          width: 49px;
        }
      }
    }
  }

  .container-person-info {
    border-bottom: 1px solid #e8e8e8;
    margin: 0 30px;
    padding-bottom: 19px;

    @include breakpoint-for(desktop-small) {
      margin: 0 30px;
    }

    .title-card-person {
      span {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: $text_primary_dark_color;
        display: -webkit-box;
        font-family: $primary_font_demi;
        font-size: pxToRem(14px);
        margin: 0;
        overflow: hidden;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(21px);
        }
      }

      p {
        color: $text_primary_dark_color;
        font-family: $primary_font_book;
        font-size: pxToRem(13px);
        margin: 6px 0 0 0;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(16px);
        }
      }

      .sub-title-card {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        min-height: 116px;

        .company-name {
          align-items: center;
          display: flex;
          margin-bottom: 20px;

          img {
            border-radius: 7px;
            height: 36px;
            margin-right: 12px;
            width: 36px;

            @include breakpoint-for(desktop-small) {
              height: 46px;
              margin-right: 12px;
              width: 46px;
            }
          }

          p {
            color: $text_primary_dark_color;
            font-family: $primary_font_book;
            font-size: pxToRem(21px);
            margin: 0;
            width: 200px;
          }
        }
      }
    }

    .tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      height: 65px;

      @include breakpoint-for(desktop-small) {
        margin-top: 20px;
      }

      .tag {
        align-items: center;
        background-color: white;
        border-radius: 30px;
        border: 1px solid #cccccc;
        color: $text_primary_dark_color;
        display: flex;
        font-family: $primary_font_book;
        height: 25px;
        margin: 0;
        outline: none;
        padding: 7px 10px;
        width: initial;

        @include breakpoint-for(desktop-small) {
          width: max-content;
        }

        p {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          font-size: pxToRem(13px);
          margin: 0;
          overflow: hidden;
        }
      }
    }

    .container-badges {
      margin-top: 45px;

      .two-badge {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
      }

      .badge {
        align-items: center;
        display: flex;

        p {
          color: $text_primary_dark_color;
          font-family: $primary_font_book;
          font-size: pxToRem(13px);
        }

        img {
          height: 17px;
          margin-right: 7px;
          width: 19px;
        }

        &.last {
          margin-top: 10px;
        }
      }
    }
  }

  .container-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 30px;

    .Button {
      font-size: pxToRem(14px);
      height: 55px;
      padding: 0;
      width: 100%;
    }
  }

  .container-text {
    display: flex;
    justify-content: space-between;
    margin: 10px 30px;

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_book;
      font-size: pxToRem(12px);
    }
  }
}
