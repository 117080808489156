@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

:root {
  --gap: 99px;
}

.ButtonSlider {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  overflow: hidden;
  position: relative;
  width: 100%;

  .slider-button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    margin: 0 10px;
    outline: none;
    padding: 10px 20px;
  }

  .prev-button {
    left: -20px;
    position: relative;

    img {
      transform: rotate(180deg);
      width: 15px;
    }
  }

  .next-button {
    position: relative;
    right: -20px;

    img {
      width: 15px;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 0 80px;
    white-space: nowrap;
    width: 90%;
  }

  .buttons {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }

  .button {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 0 23px;
    margin-right: var(--gap);
    padding: 10px;
    width: 137px;

    img {
      border-radius: 50%;
      height: 138px;
      width: 137px;
    }

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(20px);
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
