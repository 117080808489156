@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.CreateListFavorite {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.active {
    display: flex;
  }

  .modal-status-preview {
    background-color: #FAFBFB;
    border-radius: 9px;
    max-height: 379px;
    max-width: 643px;
    padding: 35px 30px;
    position: relative;
    width: 100%;

    .close-modal {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .x-container {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 20px;
        position: relative;
        width: 30px;

        .x-line {
          background-color: #707070;
          height: 2px;
          position: absolute;
          width: 100%;
        }

        .x-line-left {
          transform: rotate(45deg);
        }

        .x-line-right {
          transform: rotate(-45deg);
        }
      }
    }

    .box-input-create {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 30px;

      .Input {
        width: 100%;

        label {
          color: $background_primary_blue_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(25px);
          margin-bottom: 28px;
        }

        input {
          border-radius: 9px;
          border: 1px solid #707070;
          height: 70px;
        }
      }

      .Button {
        height: 54px;
        padding: 0;
        width: 192px;
      }
    }
  }
}
