@import '../../assets/scss/variables';
@import '../../assets/scss/function';
@import '../../assets/scss/mixins';


.NewVacancies {
  background-color: $background_white_color;
  max-height: 100vh;
  overflow-y: scroll;
  width: 100%;

  .menu-vacancies {
    background-color: $background_light_grey;
    height: 170px;
    padding: 58px 125px;
    position: relative;
    z-index: 2;

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(22px);
      margin: 0;
    }

    .info {
      align-items: center;
      display: flex;
      height: 50px;

      &.sketch {
        p {
          margin-right: 26px;
        }

        .Button {
          background-color: white;
        }
      }
    }

    .button-relative {
      align-items: flex-start;
      background: #F2F4F5;
      border-radius: 21px;
      border: 1px solid #CCCCCC;
      bottom: -15px;
      color: $text_secondary_dark_color;
      cursor: pointer;
      display: flex;
      font-family: $primary_font_book;
      font-size: pxToRem(16px);
      height: 66px;
      justify-content: center;
      padding: 19px 0;
      position: absolute;
      right: 120px;
      text-decoration: none;
      width: 206px;

      &.active {
        background-color: $background_white_color;
      }
    }
  }

  .container-new-vacancies {
    background-color: $background_white_color;
    padding: 58px 125px;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 4;

    p {
      margin: 0;
    }

    .new-job {
      display: flex;

      .left-new {
        width: 40%;

        .title-container {
          color: $text_primary_dark_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(25px);
          margin-bottom: 105px;
        }

        .Input,
        select {
          margin-bottom: 20px;
          max-width: 567px;
        }

        select {
          margin-bottom: 34px;
        }

        .radio-btn-container {
          .title {
            margin: 20px 0 20px 0;

            &.text {
              margin: 20px;
            }
          }
        }
      }

      .right-new {
        width: 60%;

        .radio-btn-container {
          div {
            gap: 0 35px;
          }
        }

        .Select {
          margin: 37px 0;

          select {
            max-width: 567px;
            width: 100%;
          }
        }

        .container-text {
          .title {
            display: flex;
            margin: 40px 0 27px 0;
          }

          .quill {
            border-radius: 4px !important;
            height: 204px !important;
          }

          .ql-toolbar,
          .ql-container {
            border-radius: 4px 4px 0 0 !important;
            border: 1px solid #E8E8E8 !important;
            font-family: $primary_font_book;
            font-size: pxToRem(17px);

            button {
              height: 35px;
              width: 35px;
            }
          }

          .ql-container {
            border-radius: 0 0 4px 4px !important;
            border-top: none !important;
          }
        }

        .select-tags {
          margin: 85px 0 0 0;

          .title {
            display: flex;
            font-weight: 500;
            margin: 40px 0 27px 0;
          }

          .container-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            height: 200px;
            margin-top: 56px;
            overflow-y: scroll;
            width: 100%;

            @include breakpoint-for(desktop-large) {
              gap: 9px;
            }
          }
        }
      }

      .container-buttons {
        display: flex;
        gap: 0 15px;
        justify-content: flex-start;
        margin-top: 60px;
        width: 100%;

        .Button {
          font-family: $primary_font_book;
          font-size: pxToRem(19px);
          font-weight: 700;
        }
      }
    }

    .sketch-container {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
    }
  }
}
