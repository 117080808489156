@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.ContactLanding {
  background-color: #010230;
  height: 100%;
  padding-bottom: 200px;

  .top-contact-container {
    height: 500px;

    .top-contact {
      align-items: flex-end;
      display: block;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        padding-left: 84px;
      }

      @include breakpoint-for(desktop-medium) {
        display: flex;
      }

      .left-top-contact {
        height: 300px;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          height: 460px;
          width: 60%;
        }

        @include breakpoint-for(desktop-medium) {
          height: 510px;
        }

        .box-navigator {
          background-color: #010230;
          border: 1px solid #FFFFFF;
          height: 174px;
          width: 80%;
          margin-left: 20px;

          &.content {
            background-color: #010E63;
            left: 30px;
            position: relative;
            top: -80px;
          }

          .top-navigator {
            border-bottom: 1px solid #FFFFFF;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .image {
              border-left: 1px solid #FFFFFF;
              height: 100%;
              padding: 5px 10px;

              img {
                height: 10px;
                width: 10px;
              }
            }
          }

          .content-box {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 20px;
            width: 100%;

            p {
              color: $text_primary_light_color;
              font-family: $primary_font_demi;
              font-size: pxToRem(20px);
              margin: 0;
            }
          }

          @include breakpoint-for(desktop-small) {
            border: 2px solid #FFFFFF;
            height: 334px;
            width: 627px;

            &.content {
              left: 150px;
              position: relative;
              top: -240px;
            }

            .top-navigator {
              border-bottom: 2px solid #FFFFFF;
              display: flex;
              justify-content: flex-end;
              width: 100%;

              .image {
                border-left: 2px solid #FFFFFF;
                height: 100%;
                padding: 15px 18px;

                img {
                  height: 22px;
                  width: 22px;
                }
              }
            }

            .content-box {
              display: flex;
              height: 100%;
              padding: 90px 64px 40px 64px;
              width: 100%;

              p {
                color: $text_primary_light_color;
                font-family: $primary_font_demi;
                font-size: pxToRem(25px);
                margin: 0;
              }
            }
          }

          @include breakpoint-for(desktop-medium) {
            height: 374px;
            width: 827px;

            .content-box {
              p {
                font-size: pxToRem(45px);
              }
            }
          }
        }
      }

      .right-top-contact {
        display: flex;
        gap: 0 30px;
        margin: 0 10px 0 55px;

        p {
          color: white;
          font-size: pxToRem(13px);
          margin: 0;
        }

        @include breakpoint-for(desktop-small) {
          gap: 0 100px;
          margin-left: 220px;

          p {
            font-size: pxToRem(16px);
          }
        }


        @include breakpoint-for(desktop-medium) {
          margin-left: 200px;
        }

        @include breakpoint-for(desktop-large) {
          margin-left: 120px;
        }
      }
    }
  }

  .center-contact {
    margin-top: 100px;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      margin-top: 250px;
    }

    .center-contact-box {
      display: flex;
      justify-content: flex-start;
      position: relative;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        justify-content: center;
      }

      .box-navigator {
        background-color: #010230;
        border: 1px solid #FFFFFF;
        height: 174px;
        left: 70px;
        position: relative;
        top: -120px;
        width: 80%;
        z-index: 1;

        @include breakpoint-for(desktop-small) {
          left: 150px;
          height: 485px;
          width: 827px;
        }

        &.content {
          background-color: #010E63;
          left: 20px;
          position: absolute;
          right: unset;
          top: unset;
          z-index: 2;
        }

        .top-navigator {
          border-bottom: 1px solid #FFFFFF;
          display: flex;
          justify-content: flex-end;
          width: 100%;

          .image {
            border-left: 1px solid #FFFFFF;
            height: 100%;
            padding: 5px 10px;

            img {
              height: 10px;
              width: 10px;
            }
          }
        }

        .content-box {
          display: flex;
          padding: 15px;
          width: 100%;

          p {
            color: $text_primary_light_color;
            font-family: $primary_font_demi;
            font-size: pxToRem(20px);
            margin: 0;
          }

          @include breakpoint-for(desktop-small) {
            padding: 73px 64px 40px 64px;

            p {
              font-size: pxToRem(45px);
            }
          }
        }

        @include breakpoint-for(desktop-small) {
          width: 60%;

          &.content {
            background-color: #010E63;
            left: unset;
            position: absolute;
            right: unset;
            top: unset;
            z-index: 2;
          }

          .top-navigator {
            border-bottom: 2px solid #FFFFFF;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .image {
              border-left: 2px solid #FFFFFF;
              height: 100%;
              padding: 15px 18px;

              img {
                height: 22px;
                width: 22px;
              }
            }
          }
        }
      }
    }
  }

  .form-contact {
    margin-top: 100px;
    padding: 0 20px;

    strong {
      color: $text_primary_light_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(24px);
    }

    
    @include breakpoint-for(desktop-small) {
      margin-top: 200px;
      padding: 0 84px;

      strong {
        color: $text_primary_light_color;
        font-family: $primary_font_demi;
        font-size: pxToRem(37px);
      }
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: 54px;
      width: 100%;

      .list-input {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .container-inputs {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          gap: 39px 24px;
          width: 100%;
          margin-top: 39px;

          @include breakpoint-for(desktop-small) {
            width: 49%;
          }

          .input-wrapper {
            align-items: center;
            border-radius: 5px;
            border: 2px solid #FFFFFF;
            display: flex;
            height: 83px;
            width: 100%;

            .input-icon {
              color: $text_primary_light_color;
              font-size: pxToRem(15px);
              margin: 0 10px 0 40px;
              width: 81px;
            }

            @include breakpoint-for(desktop-small) {
              .input-icon {
                font-size: pxToRem(20px);
              }
            }

            input {
              align-items: flex-end;
              background-color: transparent;
              border: none;
              color: $text_primary_light_color;
              display: flex;
              font-size: pxToRem(20px);
              height: 100%;
              margin: 0;
              outline: none;
              width: 90%;
            }
          }
        }
      }

      button {
        background-color: #010E63;
        border: 2px solid $text_primary_light_color;
        color: #FFFFFF;
        cursor: pointer;
        font-family: $primary_font_demi;
        font-size: 1rem;
        margin-top: 39px;
        padding: 22px 50px;
        width: 200px;
      }
    }
  }
}