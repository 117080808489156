@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.TitleLanding {
  background-color: #010230;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 20px 70px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    align-items: flex-start;
    justify-content: space-between;
    padding: 100px 146px 229px 84px;
  }


  @include breakpoint-for(desktop-medium) {
    align-items: flex-start;
    justify-content: space-between;
    padding: 229px 146px 229px 84px;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: $text_primary_light_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(80px);
      line-height: 70px;
      margin: 0;

      span {
        -webkit-transform: matrix(.99, .12, -.12, .99, 0, 0);
        color: #01C7F0;
        display: inline-block;
        transform: matrix(.99, .12, -.12, .99, 0, 0);
      }

      @include breakpoint-for(desktop-medium) {
        font-size: pxToRem(122px);
        width: 423px;
      }
    }

    p {
      display: none;
      color: $text_primary_light_color;
      font-family: $primary_font_book;
      font-size: 20px;
      margin: 40px 0 0 0;
    }

    button {
      background-color: transparent;
      border-radius: 32px;
      border: 2px solid #01C7F0;
      color: #01C7F0;
      display: none;
      font-family: $primary_font_demi;
      font-size: pxToRem(16px);
      font-weight: 900;
      margin-top: 140px;
      padding: 22px 50px;
    }

    .button-mobile {
      background-color: #010E63;
      border-radius: 32px;
      border: 2px solid #010E63;
      color: $text_primary_light_color;
      display: flex;
      font-family: $primary_font_demi;
      font-size: pxToRem(16px);
      margin: 0;
      outline: none;
      padding: 22px 50px;
      text-decoration: unset;
    }

    .sub-title-mobile {
      display: flex;
      margin: 120px 0;
      text-align: center;
    }

    @include breakpoint-for(desktop-small) {
      display: block;

      h1 {
        font-size: pxToRem(80px);
        line-height: 102px;
        margin: 0;
        width: 300px;
      }

      p {
        display: flex;
      }

      .sub-title-mobile {
        display: none;
      }

      button {
        display: flex;
      }

      .button-mobile {
        display: none;
      }
    }

    @include breakpoint-for(desktop-medium) {
      h1 {
        font-size: pxToRem(120px);
        line-height: 102px;
        margin: 0;
        width: 500px;
      }
    }
  }

  .list-students {
    display: none;
    justify-content: flex-end;
    padding-bottom: 422px;
    margin-right: -45px;

    @include breakpoint-for(desktop-small) {
      display: flex;
    }

    .box-student {
      background-color: #010230;
      align-items: flex-end;
      border: 2px solid #FFFFFF;
      display: flex;
      flex-direction: column;
      height: 441px;
      min-width: 301px;
      width: 100%;

      &.relative {
        position: relative;
        left: 175px;
        top: 190px;
      }

      img {
        padding: 25px 20px;
      }

      @include breakpoint-for(desktop-medium) {
        margin-right: 0;
        height: 441px;
        min-width: 401px;
        width: 100%;
      }


      .student-info {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 10px 25px 61px 25px;
        width: 100%;

        strong {
          color: #01C7F0;
          font-family: $primary_font_demi;
          font-size: pxToRem(25px);
        }

        p {
          color: $text_primary_light_color;
          font-family: $primary_font_book;
          font-size: pxToRem(18px);
          margin: 10px 0 0 0;
        }

        button {
          background-color: transparent;
          border: 2px solid #FFFFFF;
          color: $text_primary_light_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(17px);
          height: 71px;
          width: 100%;
        }
      }
    }
  }
}