@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.SearchResult {
  max-height: 100vh;
  overflow-y: scroll;
  padding: 50px 15px 120px 15px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 50px 42px 100px;
  }

  .container-filter {
    align-items: center;
    background-color: $background_primary_light_color;
    border-radius: 29px;
    display: flex;
    margin-top: 20px;
    min-height: 243px;
    padding: 30px 100px;
  }

  .container-student-home {
    align-items: center;
    background: #fafbfb 0% 0% no-repeat padding-box;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 20px 15px;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      border-radius: 29px;
      padding: 46px 69px 27px;
    }

    .search-bar {
      align-items: center;
      background-color: $background_white_color;
      border-radius: 9px;
      border: 1px solid #e8e8e8;
      display: flex;
      height: 68px;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        border-radius: 34px;
        margin-top: 26px;
      }

      form {
        width: 100%;
      }

      .search-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 100%;
        margin: 10px;
        padding: 5px;
        width: 60px;
      }

      .search-input {
        background-color: inherit;
        background-color: transparent;
        border: none;
        flex: 1;
        font-family: $primary_font_book;
        font-size: pxToRem(17px);
        height: 85%;
        outline: none;
        padding: 5px;
        width: 80%;

        &::placeholder {
          color: $text_primary_light_placeholder_color;
        }
      }
    }

    .recommentation-job-home {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      img {
        margin-right: 17px;
      }

      p {
        color: $text_secondary_dark_color;
        font-family: $primary_font_demi;
        font-size: pxToRem(18px);
      }
    }

    .list-recommendation-jobs {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }
  }
}
