@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.CandidateProfile {
  background-color: $background_white_color;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;

  @include breakpoint-for(desktop-extra-large-4k) {
    max-width: 1511px;
  }

  .menu-job-details {
    background-color: $background_light_grey;
    height: 170px;
    display: none;
    padding: 58px 125px;
    position: relative;
    z-index: 2;

    @include breakpoint-for(desktop-small) {
      display: block;
    }

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(17px);
      margin: 0;
    }

    .info {
      align-items: center;
      display: flex;
      height: 50px;

      .info-job {
        align-items: center;
        display: flex;

        .button-edit {
          margin-left: 40px;
        }

        p {
          cursor: pointer;
          margin: 0;

          &:hover {
            filter: brightness(1.3);
          }
        }

        img {
          margin: 0 30px 0 20px;
        }

        span {
          color: $background_primary_blue_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(17px);
        }
      }
    }
  }

  .container-job-user-details {
    background-color: $background_white_color;
    padding: 25px 15px 120px 15px;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 4;

    @include breakpoint-for(desktop-small) {
      padding: 58px 125px;
    }

    .button-edit {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .Button {
        border-radius: 9px;
        width: 100%;
      }

      @include breakpoint-for(desktop-small) {
        display: none;
      }
    }

    .images-container {
      position: relative;

      .background {
        border-radius: 20px;
        height: 133px;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          height: 248px;
        }
      }
    }

    .container-info {
      width: 100%;

      @include breakpoint-for(desktop-large) {
        padding: 0 5%;
      }

      .info-button {
        display: flex;
        flex-direction: column;

        .first-container-info {
          display: flex;
          justify-content: flex-start;
          margin-top: 61px;
          padding: 0 0 0 36px;

          @include breakpoint-for(desktop-small) {
            margin-top: 41px;
            padding: 0 5%;
          }

          .person-info {
            display: flex;
            flex-direction: column;
            gap: 7px 0;
            margin-top: 15px;

            .person {
              display: flex;
              flex-direction: column;
              gap: 7px 0;

              .box-person {
                align-items: center;
                display: flex;

                img {
                  height: 17px;
                  margin-right: 7px;
                  width: 17px;
                }

                p {
                  font-family: $primary_font_book;
                  font-size: pxToRem(14px);
                  margin: 0;
                }

                .inative {
                  display: flex;

                  span {
                    background: #e9e9e9 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    display: inline-block;
                    height: 17px;
                    width: 120px;
                  }
                }
              }
            }

            @include breakpoint-for(desktop-small) {
              flex-direction: row;
              gap: 0 20px;

              .person {
                gap: 15px 0;
              }
            }
          }
          .title-and-info {
            .title-details {
              align-items: center;
              display: flex;

              h3 {
                color: $text_primary_dark_color;
                font-family: $primary_font_demi;
                font-size: pxToRem(18px);
                margin: 0;

                @include breakpoint-for(desktop-small) {
                  font-size: pxToRem(30px);
                }
              }
            }
          }
        }

        .box-description {
          background-color: $background_primary_light_color;
          border-radius: 9px;
          display: flex;
          flex-direction: column;
          margin: 50px 0 0 0;
          padding: 25px 0 25px 36px;

          .description {
            font-size: pxToRem(14px);
            margin-bottom: 30px;
            width: 100%;
          }

          p {
            font-family: $primary_font_book;
            font-size: pxToRem(14px);
            margin: 0;
          }

          @include breakpoint-for(desktop-small) {
            border-radius: 29px;
            padding: 45px 5%;

            .description {
              font-size: pxToRem(21px);
            }

            p {
              font-size: pxToRem(19px);
            }
          }
        }

        .projects-container {
          margin-top: 55px;

          .project {
            margin-top: 25px;
            display: flex;
            background-color: #ebebf4;
            border-radius: 15px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            gap: 35px;

            @include breakpoint-for(desktop-small) {
              width: 576px;
              height: 325px;
              flex-direction: row;
            }

            .list-favorite {
              background-color: white;
              border-radius: 16px;
              box-shadow: 0px 3px 11px #00000005;
              display: block;
              height: 273px;
              padding: 31px 24px;
              width: 230px;

              .info-list {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;

                div {
                  margin-top: 5px;

                  p {
                    color: $background_primary_blue_color;
                    font-family: $primary_font_demi;
                    font-size: pxToRem(19px);
                    margin: 0 0 31px 0;
                  }

                  hr {
                    background: #00016e 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    height: 5px;
                    margin: 8px 0;
                    opacity: 0.11;
                    width: 65px;
                  }

                  span {
                    color: $background_primary_blue_color;
                    display: block;
                    font-family: $primary_font_demi;
                    font-size: pxToRem(19px);
                    margin: 25px 0;
                  }
                }

                .Input {
                  width: 90%;
                  height: 30px;
                }

                .Button {
                  font-size: pxToRem(19px);
                  height: 55px;
                  padding: 0;
                  width: 100%;
                }
              }
            }
          }

          strong {
            font-size: pxToRem(14px);
            font-family: $primary_font_demi;
            padding: 0 0 0 36px;
            
            @include breakpoint-for(desktop-small) {
              font-size: pxToRem(21px);
              padding: 0 5%;
            }
          }

          @include breakpoint-for(desktop-small) {
            margin-top: 68px;
          }
        }

        .skills-container {
          margin-top: 55px;
          padding: 0 0 0 36px;

          strong {
            font-size: pxToRem(14px);
            font-family: $primary_font_demi;
          }

          .skills {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            margin-top: 24px;
            width: 100%;

            @include breakpoint-for(desktop-large) {
              gap: 9px;
            }

            .skill {
              align-items: center;
              background-color: white;
              border-radius: 30px;
              border: 1px solid #cccccc;
              color: $text_primary_dark_color;
              display: flex;
              font-family: $primary_font_book;
              font-size: pxToRem(13px);
              height: 39px;
              justify-content: center;
              margin: 0;
              outline: none;
              padding: 0 10px 0 10px;
              transition: border 0.8s ease;
              width: auto;
            }
          }

          @include breakpoint-for(desktop-small) {
            margin-top: 68px;
            padding: 0 5%;

            strong {
              font-size: pxToRem(21px);
            }

            .skills {
              margin-top: 56px;
            }
          }
        }
      }
    }

    .info-jobs {
      font-family: $primary_font_book;
      font-size: pxToRem(19px);
      margin: 76px 0 !important;
      text-align: center;
      width: 100%;
    }
  }
}
