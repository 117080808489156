@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";

.SelectWithIcon {
  background-color: $background_white_color;
  border-radius: 21px;
  border: 1px solid #e8e8e8;
  display: inline-block;
  height: 41px;
  position: relative;
  min-width: 120px;

  .select-icon {
    left: 17px;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
  }

  .select-content {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding: 0 10px;

    .select-name {
      color: $text_primary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(17px);
      margin-right: 20px;
      margin: 0 20px 0 30px;
      text-align: center;
      min-width: 50px;
    }

    .arrow {
      display: inline-block;
      height: 2px;
      transform: rotate(90deg);
      width: 10px;
    }

    .select-arrow {
      margin-left: 5px;
    }

    .hidden-select {
      display: none;
    }
  }

  .options-list {
    background-color: $background_white_color;
    border-radius: 21px;
    border: 1px solid #e8e8e8;
    color: $text_primary_dark_color;
    font-family: $primary_font_demi;
    font-size: pxToRem(17px);
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 1;

    .option {
      cursor: pointer;
      padding: 5px 10px;

      &.disable {
        cursor: not-allowed;
        color: #aaa;
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
