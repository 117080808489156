@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.VideoLanding {
  background-color: #010230;
  height: 100%;
  padding: 0 20px 50px;

  @include breakpoint-for(desktop-medium) {
    padding: 0 20px 200px;
  }

  .box-navigator {
    background-color: #FFFFFF;
    border: 2px solid #010230;
    height: 174px;
    left: 20px;
    position: relative;
    top: -60px;
    width: 85%;

    @include breakpoint-for(desktop-small) {
      height: 307px;
      left: 100px;
      position: relative;
      top: -100px;
      width: 771px;
    }

    .top-navigator {
      border-bottom: 1px solid #010230;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .image {
        border-left: 1px solid #FFFFFF;
        height: 100%;
        padding: 5px 10px;

        img {
          height: 10px;
          width: 10px;
        }
      }

      @include breakpoint-for(desktop-small) {
        border-bottom: 2px solid #010230;

        .image {
          border-left: 2px solid #010230;
          height: 100%;
          padding: 15px 18px;

          img {
            height: 22px;
            width: 22px;
          }
        }
      }
    }

    .content-box {
      display: flex;
      align-items: flex-end;
      height: 70%;
      padding: 20px 34px;
      width: 100%;

      p {
        color: #010230;
        font-family: $primary_font_demi;
        font-size: pxToRem(27px);
        margin: 0;
      }


      @include breakpoint-for(desktop-small) {
        align-items: flex-end;
        padding: 20px 64px 40px 64px;

        p {
          font-size: pxToRem(69px);
        }
      }
    }
  }

  .container-video {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;

    .contents-video {
      max-width: 1142px;
      width: 100%;

      .box-video {
        background-color: #010230;
        border: 2px solid #FFFFFF;
        display: none;
        height: 615px;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          display: block;
        }

        .top-video {
          border-bottom: 2px solid #FFFFFF;
          display: flex;
          justify-content: flex-end;
          width: 100%;

          .image {
            border-left: 2px solid #FFFFFF;
            height: 100%;
            padding: 15px 18px;

            img {
              height: 22px;
              width: 22px;
            }
          }
        }

        .content-box {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          width: 100%;

          p {
            color: $text_primary_light_color;
            font-family: $primary_font_demi;
            font-size: pxToRem(69px);
            margin: 0;
          }
        }
      }
    }

    .list-box-video {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include breakpoint-for(desktop-small) {
        margin-top: 98px;
      }


      .container-mini-box-video {
        width: 180px;

        @include breakpoint-for(desktop-small) {
          width: 230px;
        }

        .mini-box-video {
          background-color: #010E63;
          border: 1px solid #FFFFFF;
          height: 130px;
          width: 180px;

          .mini-box-top {
            border-bottom: 1px solid #FFFFFF;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .image {
              border-left: 2px solid #FFFFFF;
              height: 100%;
              padding: 5px 8px;

              img {
                height: 11px;
                width: 11px;
              }
            }
          }

          @include breakpoint-for(desktop-small) {
            border: 2px solid #FFFFFF;
            height: 155px;
            width: 230px;

            .mini-box-top {
              border-bottom: 2px solid #FFFFFF;

              .image {
                border-left: 2px solid #FFFFFF;
                height: 100%;
                padding: 8px 11px;

                img {
                  height: 11px;
                  width: 11px;
                }
              }
            }
          }

          .content-box {
            align-items: flex-end;
            display: flex;
            height: 70%;
            padding: 74px 0 20px 14px;
            width: 100%;

            p {
              color: #FFFFFF;
              font-family: $primary_font_book;
              font-size: pxToRem(15px);
              margin: 0;
            }

            @include breakpoint-for(desktop-small) {
              p {
                font-family: $primary_font_demi;
                font-size: pxToRem(20px);
              }
            }
          }
        }

        .description-mini-box {
          color: #FFFFFF;
          font-family: $primary_font_book;
          font-size: pxToRem(14px);
          height: 177px;
          padding-left: 14px;

          @include breakpoint-for(desktop-small) {
            font-size: pxToRem(16px);
          }
        }
      }
    }
  }
}