@import "../../../assets/scss/_variables";
@import "../../../assets/scss/_mixins";
@import "../../../assets/scss/_function";

.ModalStatus {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.active {
    display: flex;
  }

  .modal-status-preview {
    background-color: #fafbfb;
    border-radius: 9px;
    height: max-content;
    max-width: 1507px;
    padding: 35px 45px;
    position: relative;
    width: 100%;

    .close-modal {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .x-container {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 20px;
        position: relative;
        width: 30px;

        .x-line {
          background-color: #707070;
          height: 2px;
          position: absolute;
          width: 100%;
        }

        .x-line-left {
          transform: rotate(45deg);
        }

        .x-line-right {
          transform: rotate(-45deg);
        }
      }
    }

    .container-modal-status {
      align-items: flex-start;
      display: flex;
      height: 100%;
      justify-content: space-between;
      margin-top: 38px;
      width: 100%;

      .info-user-modal {
        max-width: 470px;
        width: 100%;

        span {
          color: $text_primary_dark_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(31px);
        }

        .profession {
          color: $text_primary_dark_color;
          font-family: $primary_font_book;
          font-size: pxToRem(23px);
          margin: 9px 0 0 0;
        }

        .tags-modal {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          margin: 25px 0 30px 0;
          max-width: 320px;

          .tag {
            align-items: center;
            background-color: white;
            border-radius: 30px;
            border: 1px solid #cccccc;
            color: $text_primary_dark_color;
            display: flex;
            font-family: $primary_font_book;
            height: 29px;
            margin: 0;
            outline: none;
            padding: 7px 10px;
            width: max-content;

            p {
              font-size: pxToRem(11px) !important;
              margin: 0;
            }
          }
        }

        .person-info {
          display: flex;
          gap: 0 58px;

          .person {
            display: flex;
            flex-direction: column;
            gap: 9px 0;

            .box-person {
              align-items: center;
              display: flex;

              img {
                height: 17px;
                margin-right: 7px;
                width: 17px;
              }

              p {
                font-family: $primary_font_book;
                font-size: pxToRem(14px);
              }

              .inative {
                display: flex;

                span {
                  background: #e9e9e9 0% 0% no-repeat padding-box;
                  border-radius: 5px;
                  display: inline-block;
                  height: 17px;
                  width: 120px;
                }
              }
            }
          }
        }

        .description-person {
          margin-top: 25px;
          width: 100%;

          .title-description {
            background-color: #f2f4f5;
            border-radius: 9px;
            color: #4d5a60;
            display: flex;
            font-family: $primary_font_demi;
            font-size: pxToRem(16px);
            padding: 9px 16px;
            width: 100%;
          }

          .description-text {
            margin-top: 32px;
            max-height: 300px;
            overflow-y: scroll;
            padding: 0 10px;
          }

          .description-text::-webkit-scrollbar {
            height: 3px;
            width: 3px;
          }

          .description-text::-webkit-scrollbar-track {
            background: #4d5a6075;
            border-radius: 10px !important;
          }

          .description-text::-webkit-scrollbar-thumb {
            background: #4d5a60;
            border: none;
          }
        }
      }

      .division {
        background-color: red;
        display: inline-block;
        min-width: 77px;
      }

      .status-candidate {
        background-color: #f2f4f5;
        border-radius: 9px;
        max-width: 921px;
        padding: 36px 26px;
        width: 100%;

        .title-status-candidate {
          align-items: center;
          display: flex;
          margin-left: 10px;

          .image-container {
            align-items: center;
            background-color: white;
            border-radius: 9px;
            display: flex;
            height: 71px;
            justify-content: center;
            width: 69px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 9px;
            }
          }

          span {
            font-family: $primary_font_demi;
            font-size: pxToRem(19px);
            margin-left: 14px;
          }
        }

        .description-status {
          font-family: $primary_font_book;
          font-size: pxToRem(17px);
          margin: 40px 0 26px 23px;
        }

        .container-status-person {
          display: flex;
          gap: 20px 0;
          position: relative;

          .box-person-container {
            align-items: center;
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 20px;
          }

          .timeline {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 20px;
            left: -80px;
            position: absolute;

            .timeline-item {
              align-items: center;
              display: flex;
              height: 99px;

              &:last-child {
                ::before {
                  height: 0;
                }
              }
            }

            .number {
              align-items: center;
              background-color: #00016e;
              border-radius: 50%;
              color: #fff;
              display: flex;
              font-family: $primary_font_demi;
              font-size: pxToRem(15px);
              height: 24px;
              justify-content: center;
              position: relative;
              width: 24px;

              &.inative {
                background-color: #c0c6c8;
              }
            }

            .number::before {
              background-color: #c0c6c8;
              content: "";
              height: 100px;
              left: 50%;
              position: absolute;
              top: 100%;
              transform: translateX(-50%);
              width: 0.2px;
            }
          }

          .box-status {
            align-items: center;
            background-color: #fafbfb;
            border-radius: 20px;
            border: 1px solid #eaedef;
            box-shadow: 0px 3px 11px #00000005;
            display: flex;
            display: flex;
            height: 99px;
            padding: 27px 25px;
            width: 100%;

            &.inative {
              opacity: 0.61;

              img {
                height: 40px;
                width: 40px;
              }

              p {
                color: #4d5a60;
              }
            }

            img {
              height: 45px;
              margin-right: 23px;
              width: 45px;
            }

            p {
              color: $background_primary_blue_color;
              font-family: $primary_font_demi;
              font-size: pxToRem(19px);
              margin-right: 23px;
            }

            button {
              background: #ebebf4;
              border-radius: 15px;
              border: 1px solid #ebebf4;
              color: $background_primary_blue_color;
              cursor: pointer;
              font-family: $primary_font_demi;
              font-size: pxToRem(15px);
              height: 54px;
              padding: 0 21px;

              &:hover {
                background-color: transparent;
                border: 1px solid #00016e;
              }

              &.active {
                background-color: transparent;
                border: 1px solid #00016e;

                &:hover {
                  border: 1px solid #ebebf4;
                  background: #ebebf4;
                  filter: brightness(1);
                }
              }
            }

            .two-buttons {
              display: flex;
              gap: 0 15px;
              height: 54px;
              justify-content: flex-end;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}
