@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.SwitchableJobCard {
  background-color: $background_white_color;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  height: 341px;
  position: relative;
  width: 364px;

  @include breakpoint-for(desktop-small) {
    height: 100%;
    min-height: 386px;
  }

  .switch-button {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    z-index: 2;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 10px;
      margin: 20px 20px 0 0;
      outline: none;
      position: absolute;

      img {
        height: 49px;
        width: 49px;
      }
    }
  }

  .container-person-info {
    border-bottom: 1px solid #e8e8e8;
    margin: 0 30px;
    padding-bottom: 19px;

    .title-card-person {
      span {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: $text_primary_dark_color;
        display: -webkit-box;
        font-family: $primary_font_demi;
        font-size: pxToRem(14px);
        height: 35px;
        margin: 0;
        max-height: 35px;
        overflow: hidden;
      }

      p {
        color: $text_primary_dark_color;
        font-family: $primary_font_book;
        font-size: pxToRem(16px);
        margin: 6px 0 0 0;
      }

      .sub-title-card {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        min-height: 52px;

        @include breakpoint-for(desktop-small) {
          min-height: 116px;
        }

        .company-name {
          align-items: center;
          display: flex;
          margin-bottom: 15px;

          @include breakpoint-for(desktop-small) {
            margin-bottom: 20px;
          }

          img {
            border-radius: 7px;
            height: 27px;
            margin-right: 12px;
            width: 27px;
          }

          p {
            color: $text_primary_dark_color;
            font-family: $primary_font_book;
            font-size: pxToRem(17px);
            margin: 0;
            width: 200px;
          }
        }
      }

      @include breakpoint-for(desktop-small) {
        margin-top: 40px;

        span {
          font-size: pxToRem(21px);
          height: initial;
          max-height: initial;
        }

        p {
          font-size: pxToRem(21px);
        }
      }
    }

    .tags-container {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      height: 63px;
      margin-top: 20px;
      overflow: hidden;

      .tag {
        align-items: center;
        background-color: white;
        border-radius: 30px;
        border: 1px solid #cccccc;
        color: $text_primary_dark_color;
        display: flex;
        font-family: $primary_font_book;
        height: 25px;
        margin: 0;
        outline: none;
        padding: 7px 10px;
        width: max-content;

        p {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          font-size: pxToRem(13px);
          margin: 0;
          overflow: hidden;
        }
      }
    }

    .container-badges {
      margin-top: 20px;

      @include breakpoint-for(desktop-small) {
        margin-top: 45px;
      }

      .two-badge {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
      }

      .badge {
        align-items: center;
        display: flex;

        p {
          color: $text_primary_dark_color;
          font-family: $primary_font_book;
          font-size: pxToRem(13px);
        }

        img {
          height: 17px;
          margin-right: 7px;
          width: 19px;
        }

        &.last {
          margin-top: 10px;
        }
      }
    }
  }

  .container-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 30px;

    .Button {
      font-size: pxToRem(14px);
      height: 55px;
      padding: 0;
      width: 100%;
    }
  }

  .container-text {
    display: flex;
    justify-content: space-between;
    margin: 10px 30px;
    align-items: center;

    .info {
      width: 205px;
      height: 29px;
      display: flex;
      justify-content: flex-end;
      position: relative;

      button {
        align-items: center;
        border-radius: 29px;
        border: none;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_book;
        font-size: pxToRem(13px);
        justify-content: space-between;
        min-width: 205px;
        padding: 5px 15px;
        position: relative;
        text-transform: uppercase;

        &.WAITING_FOR_APPROVAL {
          background-color: #ffa000;
        }

        &.SELECTION_PROCESS {
          background-color: #56e14d;
        }

        &.HIRED {
          background-color: #4de1dd;
        }

        p {
          color: #000000 !important;
        }

        img {
          height: 13px;
          transform: rotate(90deg);
          width: 13px;
        }
      }

      .container-details {
        align-items: center;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: 1px solid #e8e8e8;
        box-shadow: 0px 6px 18px #0000002b;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 35px;
        width: 205px;
        z-index: 4;

        .separator {
          background-color: $text_secondary_dark_color;
          border: none;
          color: $text_secondary_dark_color;
          display: block;
          height: 1px;
          margin: 0;
          opacity: 0.2;
          width: 92%;
        }

        button {
          align-items: center;
          background-color: transparent;
          border: none;
          color: $text_secondary_dark_color;
          cursor: pointer;
          display: flex;
          font-family: $primary_font_demi;
          font-size: pxToRem(12px);
          height: 35px;
          justify-content: flex-start;
          padding-left: 20px;
          position: relative;
          width: 100%;

          &.WAITING_FOR_APPROVAL {
            color: #ffa000;
          }

          &.SELECTION_PROCESS {
            color: #56e14d;
          }

          &.HIRED {
            color: #4de1dd;
          }

          &:hover {
            filter: brightness(0.2);
          }
        }
      }
    }

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_book;
      font-size: pxToRem(12px);
    }
  }
}
