@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.FooterLanding {
  background-color: #010230;
  padding: 0 87px 90px;
  display: flex;
  justify-content: center;
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
      display: flex;
    }

    h6 {
      color: $text_primary_light_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(72px);
      line-height: 60px;
      margin: 0;

      span {
        -webkit-transform: matrix(.99, .12, -.12, .99, 0, 0);
        color: #01C7F0;
        display: inline-block;
        transform: matrix(.99, .12, -.12, .99, 0, 0);
      }

      @include breakpoint-for(desktop-small) {
        font-size: pxToRem(122px);
        line-height: 102px;
      }
    }

    p {
      color: $text_primary_light_color;
      font-family: $primary_font_book;
      font-size: pxToRem(20px);

      @include breakpoint-for(desktop-small) {
        font-size: pxToRem(41px);
      }
    }
  }
}