@import './fonts';

$primary_font_book: "Museo Regular";
$primary_font_demi: "Museo Bold";

$background_primary_blue_color: #00016E;
$background_secondary_blue_color: #DEDEFA;
$background_primary_light_color: #FAFBFB;
$background_light_grey: #F2F4F5;
$background_white_color: #FFFFFF;

$background_button_light: #EBEBF4;

$text_primary_dark_color: #000000;
$text_secondary_dark_color: #4D5A60;
$text_primary_light_color: #ffffff;
$text_primary_light_placeholder_color: #B4B4B4;

$breakpoints: (
  "mobile-medium": 400px,
  "mobile-larger": 576px,
  "tablet": 768px,
  "tablet-large": 992px,
  "desktop-small": 1024px,
  "desktop-medium": 1440px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px,
  "desktop-extra-large-4k": 2400px
);
