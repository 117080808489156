@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.NewJob {
  display: flex;
  gap: 0 40px;
  padding-right: 56px;

  .left-new {
    width: 40%;

    .title-container {
      color: $text_primary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(25px);
      margin-bottom: 105px;
    }

    .Input,
    select {
      margin-bottom: 20px;
      max-width: 567px;
    }

    select {
      margin-bottom: 34px;
    }

    .radio-btn-container {      
      .title {
        margin: 20px 0 20px 0;

        &.text {
          margin: 20px;
        }
      }
    }
  }

  .right-new {
    width: 60%;

    .radio-btn-container {
      margin-bottom: 40px;

      div {
        gap: 0 35px;
      }
    }

    .Select {
      margin: 37px 0;

      select {
        max-width: 567px;
        width: 100%;
      }
    }

    .container-text {
      .error-message {
        color: red;
      }

      .title {
        display: flex;
        margin: 40px 0 10px 0;
      }

      .quill {
        border-radius: 4px !important;
        height: 204px !important;

        &.error-message {
          .ql-toolbar,
          .ql-container {
            border: 1px solid red !important;
          }
        }
      }

      .ql-toolbar,
      .ql-container {
        background-color: $background_white_color;
        border-radius: 4px 4px 0 0 !important;
        border: 1px solid #e8e8e8 !important;
        font-family: $primary_font_book;
        font-size: pxToRem(17px);
        // max-width: 631px;

        button {
          height: 35px;
          width: 35px;
        }
      }

      .ql-container {
        border-radius: 0 0 4px 4px !important;
        border-top: none !important;
      }
    }

    .select-tags {
      margin: 85px 0 0 0;

      .title {
        display: flex;
        font-weight: 500;
        margin: 40px 0 27px 0;
      }

      .container-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        height: 200px;
        margin-top: 56px;
        overflow-y: scroll;
        width: 100%;

        @include breakpoint-for(desktop-large) {
          gap: 9px;
        }
      }
    }
  }

  .container-buttons {
    display: flex;
    gap: 0 15px;
    justify-content: flex-start;
    margin-top: 60px;
    width: 100%;

    .Button {
      font-family: $primary_font_book;
      font-size: pxToRem(19px);
      font-weight: 700;
    }
  }
}
