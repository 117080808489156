@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.JobUserDetails {
  background-color: $background_white_color;
  max-height: 100vh;
  overflow-y: scroll;
  width: 100%;
  padding-bottom: 100px;

  @include breakpoint-for(desktop-extra-large-4k) {
    max-width: 1511px;
  }

  .menu-job-details {
    background-color: $background_light_grey;
    min-height: 100px;
    padding: 40px 20px;
    position: relative;
    z-index: 2;

    @include breakpoint-for(desktop-small) {
      padding: 58px 125px;
      height: 170px;
    }

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(17px);
      margin: 0;
    }

    .info {
      align-items: center;
      display: flex;

      @include breakpoint-for(desktop-small) {
        height: 50px;
      }

      .info-job {
        align-items: center;
        display: flex;

        p {
          cursor: pointer;
          margin: 0;

          &:hover {
            filter: brightness(1.3);
          }
        }

        img {
          margin: 0 30px 0 20px;
        }

        span {
          color: $background_primary_blue_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(17px);
        }
      }
    }
  }

  .container-job-user-details {
    background-color: $background_white_color;
    padding: 58px 15px;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 4;

    @include breakpoint-for(desktop-small) {
      padding: 58px 125px;
    }

    .images-container {
      position: relative;

      .background {
        border-radius: 9px;
        height: 133px;
        width: 100%;
      }

      .profile {
        border-radius: 50%;
        border: 3px solid #ffffff;
        bottom: -40px;
        height: 83px;
        left: 60px;
        position: absolute;
        width: 83px;

        @include breakpoint-for(desktop-large) {
          left: 100px;
        }
      }

      @include breakpoint-for(desktop-small) {
        .background {
          border-radius: 20px;
          height: 248px;
        }

        .profile {
          bottom: -60px;
          height: 153px;
          width: 153px;
        }
      }
    }

    .container-info {
      .info-button {
        display: flex;
        flex-direction: column;

        .first-container-info {
          display: flex;
          justify-content: space-between;
          flex-direction: column-reverse;
          margin: 50px 0 0 0;

          @include breakpoint-for(desktop-small) {
            flex-direction: row;
            justify-content: space-between;
            margin: 20px 0 0 220px;
          }

          @include breakpoint-for(desktop-large) {
            margin: 20px 0 0 280px;
          }

          .title-and-info {
            margin-top: 10px;
            .title-details {
              display: flex;
              align-items: center;

              img {
                cursor: pointer;
                height: 39px;
                margin-left: 14px;
                width: 39px;
              }

              h3 {
                color: $text_primary_dark_color;
                font-family: $primary_font_demi;
                font-size: pxToRem(18px);
                margin: 0;

                @include breakpoint-for(desktop-small) {
                  font-size: pxToRem(30px);
                }
              }

              @include breakpoint-for(desktop-small) {
                margin: 0;
              }
            }

            .infos-details {
              display: flex;
              flex-direction: column;
              gap: 11px 0;
              margin-top: 10px;

              p {
                margin: 0;
                align-items: center;
                display: flex;
                font-family: $primary_font_book;
                font-size: pxToRem(12px);

                img {
                  height: 15px;
                  margin-right: 11px;
                  width: 15px;
                }
              }

              @include breakpoint-for(desktop-small) {
                margin-top: 29px;

                p {
                  font-size: pxToRem(14px);
                }

                img {
                  height: 20px;
                  margin-right: 11px;
                  width: 20px;
                }
              }
            }
          }

          .Button {
            border-radius: 9px;
            font-size: pxToRem(18px);
            height: 27px;

            @include breakpoint-for(desktop-small) {
              border-radius: 16px;
              font-size: pxToRem(21px);
              height: 77px;
            }
          }
        }

        .box-description {
          background-color: $background_primary_light_color;
          border-radius: 9px;
          margin: 50px 0 0 0;
          padding: 25px 15px;

          @include breakpoint-for(desktop-small) {
            border-radius: 29px;
            margin: 50px 0 0 150px;
            padding: 45px 70px;
          }

          @include breakpoint-for(desktop-large) {
            margin: 50px 0 0 210px;
          }

          h4 {
            font-family: $primary_font_demi;
            font-size: pxToRem(16px);
            margin: 0 0 31px 0;

            @include breakpoint-for(desktop-small) {
              font-size: pxToRem(21px);
            }
          }

          p {
            font-family: $primary_font_book;
            font-size: pxToRem(15px);
            margin: 0;

            @include breakpoint-for(desktop-small) {
              font-size: pxToRem(19px);
            }
          }
        }
      }
    }

    .container-card-table {
      gap: 20px 0;
      display: flex;
      flex-direction: column;
    }

    .info-jobs {
      font-family: $primary_font_book;
      font-size: pxToRem(15px);
      margin: 40px 0 !important;
      text-align: center;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        font-size: pxToRem(19px);
        margin: 76px 0 !important;
      }
    }
  }
}
