@mixin font-face($font-family, $font-weight, $font-style, $font-display, $local, $otf-suffix) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: $font-display;
    unicode-range: U+000-5FF;
    src: local($local), url($otf-suffix) format('opentype');
  }
}

@include font-face("Museo Bold",
  700,
  normal,
  swap,
  "Museo",
  "../fonts/MuseoSans-700.otf");

@include font-face("Museo Regular",
  300,
  normal,
  swap,
  "Museo",
  "../fonts/MuseoSans-300.otf");