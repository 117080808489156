@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.SelectiveProcess {
  background-color: $background_white_color;
  max-height: 100vh;
  overflow-y: scroll;
  width: 100%;

  .menu {
    background-color: $background_light_grey;
    height: 170px;
    padding: 41px 125px 58px 125px;
    position: relative;

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(22px);
      margin: 0;
    }

    .info {
      align-items: flex-start;
      display: flex;
      height: 50px;
      flex-direction: column;
      position: relative;
      // z-index: 5;

      .container-details {
        align-items: center;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: 1px solid #e8e8e8;
        box-shadow: 0px 6px 18px #0000002b;
        display: flex;
        flex-direction: column;
        // height: 94px;
        justify-content: center;
        position: absolute;
        top: 45px;
        left: 0; 
        min-width: 550px;
        z-index: 6;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .separator {
          background-color: $text_secondary_dark_color;
          // background-color: red;
          border: none;
          color: $text_secondary_dark_color;
          display: block;
          height: 1px;
          margin: 0;
          opacity: 0.2;
          width: 80%;
        }

        button {
          align-items: center;
          background-color: transparent;
          border: none;
          color: $text_secondary_dark_color;
          cursor: pointer;
          display: flex;
          font-family: $primary_font_book;
          font-size: pxToRem(19px);
          font-weight: 700;
          height: 42px;
          justify-content: center;
          position: relative;
          width: 100%;

          img {
            height: 21px;
            left: 10px;
            position: absolute;
            width: 21px;
          }

          &:hover {
            filter: brightness(0.2);
          }
        }
      }

      button {
        align-items: center;
        background-color: $background_white_color;
        border-radius: 21px;
        border: none;
        display: flex;
        height: 45px;
        justify-content: space-between;
        min-width: 550px;
        padding: 12px 25px;

        p {
          font-family: $primary_font_book;
          font-size: pxToRem(19px);
          margin: 0;
        }
      }

      img {
        margin-right: 10px;
        transform: rotate(90deg);
      }

      .info-job {
        align-items: center;
        display: flex;

        p {
          cursor: pointer;
          margin: 0;

          &:hover {
            filter: brightness(1.3);
          }
        }

        img {
          margin: 0 30px 0 10px;
        }

        span {
          color: $background_primary_blue_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(19px);
        }
      }
    }

    .container-buttons-menu {
      bottom: -30px;
      display: flex;
      gap: 0 21px;
      justify-content: space-between;
      position: relative;
      width: 100%;
      z-index: 1;

      div {
        display: flex;
        gap: 0 21px;
      }

      button {
        align-items: flex-start;
        background: #f2f4f5;
        border-radius: 21px;
        border: 1px solid #cccccc;
        color: $text_secondary_dark_color;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_book;
        font-size: pxToRem(16px);
        height: 66px;
        justify-content: center;
        padding: 13px 18px;
        text-decoration: none;
        width: max-content;

        &.active {
          background-color: $background_white_color;
          border: 1px solid $background_white_color;
        }
      }
    }
  }

  .container-new {
    background-color: $background_white_color;
    padding: 58px 125px;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 4;

    p {
      margin: 0;
    }

    .new-job {
      display: flex;

      .left-new {
        width: 40%;

        .title-container {
          color: $text_primary_dark_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(25px);
          margin-bottom: 105px;
        }

        .Input,
        select {
          margin-bottom: 20px;
          max-width: 567px;
        }

        select {
          margin-bottom: 34px;
        }

        .radio-btn-container {
          .title {
            margin: 20px 0 20px 0;

            &.text {
              margin: 20px;
            }
          }
        }
      }

      .right-new {
        width: 60%;

        .radio-btn-container {
          div {
            gap: 0 35px;
          }
        }

        .Select {
          margin: 37px 0;

          select {
            max-width: 567px;
            width: 100%;
          }
        }

        .container-text {
          .title {
            display: flex;
            margin: 40px 0 27px 0;
          }

          .quill {
            border-radius: 4px !important;
            height: 204px !important;
          }

          .ql-toolbar,
          .ql-container {
            border-radius: 4px 4px 0 0 !important;
            border: 1px solid #e8e8e8 !important;
            font-family: $primary_font_book;
            font-size: pxToRem(17px);

            button {
              height: 35px;
              width: 35px;
            }
          }

          .ql-container {
            border-radius: 0 0 4px 4px !important;
            border-top: none !important;
          }
        }

        .select-tags {
          margin: 85px 0 0 0;

          .title {
            display: flex;
            font-weight: 500;
            margin: 40px 0 27px 0;
          }

          .container-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            height: 200px;
            margin-top: 56px;
            overflow-y: scroll;
            width: 100%;

            @include breakpoint-for(desktop-large) {
              gap: 9px;
            }
          }
        }
      }

      .container-buttons {
        display: flex;
        gap: 0 15px;
        justify-content: flex-start;
        margin-top: 60px;
        width: 100%;

        .Button {
          font-family: $primary_font_book;
          font-size: pxToRem(19px);
          font-weight: 700;
        }
      }
    }
  }
}
