@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.MyJobs {
  background-color: #f2f4f5;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 0 15px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    background-color: $background_white_color;
    padding: 0;
  }

  .menu-vacancies-student {
    align-items: flex-end;
    background-color: $background_light_grey;
    display: flex;
    padding: 0;
    position: relative;
    z-index: 2;

    @include breakpoint-for(desktop-small) {
      height: 170px;
      padding: 0 125px;
    }

    .buttons-jobs-student {
      width: 100%;
      box-sizing: border-box;
      bottom: -20px;
      display: flex;
      position: relative;
      justify-content: space-between;

      div {
        display: flex;
        gap: 0 15px;
      }

      button {
        align-items: flex-start;
        background: #f2f4f5;
        border-radius: 21px;
        border: 1px solid #cccccc;
        color: #4d5a60;
        cursor: pointer;
        display: flex;
        font-family: $primary_font_book;
        font-size: pxToRem(16px);
        height: 66px;
        justify-content: center;
        padding: 13px 18px;
        text-decoration: none;
        width: max-content;

        &.active {
          background-color: $background_primary_light_color;
          border: 1px solid $background_primary_light_color;
        }

        @include breakpoint-for(desktop-small) {
          background-color: $background_white_color;
          border: 1px solid $background_white_color;
        }
      }
    }

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(22px);
      margin: 0;
    }
  }

  .menu-vacancies {
    background-color: $background_light_grey;
    height: 170px;
    padding: 58px 125px;
    position: relative;
    z-index: 2;

    p {
      color: $text_secondary_dark_color;
      font-family: $primary_font_demi;
      font-size: pxToRem(22px);
      margin: 0;
    }

    .info {
      align-items: center;
      display: flex;
      height: 50px;

      &.sketch {
        p {
          margin-right: 26px;
        }

        .Button {
          background-color: white;
        }

        .arrow {
          margin-right: 20px;
        }

        .return-button {
          cursor: pointer;
        }
      }

      .info-job {
        align-items: center;
        display: flex;

        p {
          cursor: pointer;
          margin: 0;

          &:hover {
            filter: brightness(1.3);
          }
        }

        img {
          margin: 0 30px 0 10px;
        }

        span {
          color: $background_primary_blue_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(19px);
        }
      }
    }

    .button-relative {
      align-items: flex-start;
      background: #f2f4f5;
      border-radius: 21px;
      border: 1px solid #cccccc;
      bottom: -15px;
      color: $text_secondary_dark_color;
      cursor: pointer;
      display: flex;
      font-family: $primary_font_book;
      font-size: pxToRem(16px);
      height: 66px;
      justify-content: center;
      padding: 19px 0;
      position: absolute;
      right: 120px;
      text-decoration: none;
      width: 206px;

      &.active {
        background-color: $background_white_color;
      }
    }
  }

  .container-new-vacancies {
    background-color: $background_white_color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 58px 125px;
    position: relative;
    top: -1px;
    width: 100%;
    z-index: 4;

    &.student {
      border-radius: 9px;
      padding: 0 !important;

      @include breakpoint-for(desktop-small) {
        border-radius: 0;
        padding: 58px 52px;
      }
    }

    p {
      margin: 0;
    }

    .new-job {
      display: flex;

      .left-new {
        width: 40%;

        .title-container {
          color: $text_primary_dark_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(25px);
          margin-bottom: 105px;
        }

        .Input,
        select {
          margin-bottom: 20px;
          max-width: 567px;
        }

        select {
          margin-bottom: 34px;
        }

        .radio-btn-container {
          .title {
            margin: 20px 0 20px 0;

            &.text {
              margin: 20px;
            }
          }
        }
      }

      .right-new {
        width: 60%;

        .radio-btn-container {
          div {
            gap: 0 35px;
          }
        }

        .Select {
          margin: 37px 0;

          select {
            max-width: 567px;
            width: 100%;
          }
        }

        .container-text {
          .title {
            display: flex;
            margin: 40px 0 27px 0;
          }

          .quill {
            border-radius: 4px !important;
            height: 204px !important;
          }

          .ql-toolbar,
          .ql-container {
            border-radius: 4px 4px 0 0 !important;
            border: 1px solid #e8e8e8 !important;
            font-size: pxToRem(17px);
            font-family: $primary_font_book;

            button {
              height: 35px;
              width: 35px;
            }
          }

          .ql-container {
            border-radius: 0 0 4px 4px !important;
            border-top: none !important;
          }
        }

        .select-tags {
          margin: 85px 0 0 0;

          .title {
            display: flex;
            font-weight: 500;
            margin: 40px 0 27px 0;
          }

          .container-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            height: 200px;
            margin-top: 56px;
            overflow-y: scroll;
            width: 100%;

            @include breakpoint-for(desktop-large) {
              gap: 9px;
            }
          }
        }
      }

      .container-buttons {
        display: flex;
        gap: 0 15px;
        justify-content: flex-start;
        margin-top: 60px;
        width: 100%;

        .Button {
          font-family: $primary_font_book;
          font-size: pxToRem(19px);
          font-weight: 700;
        }
      }
    }

    .sketch-container {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
    }
  }
}
