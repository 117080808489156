@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.PopUpSuccess {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;

  .info-modal {
    align-items: center;
    background: #fafbfb 0% 0% no-repeat padding-box;
    border-radius: 9px;
    display: flex;
    height: 148px;
    justify-content: center;
    opacity: 1;
    max-width: 523px;
    padding: 0 15px;

    p {
      font-family: $primary_font_demi;
      font-size: pxToRem(18px);
      margin-right: 16px;

      @include breakpoint-for(desktop-small) {
        font-size: pxToRem(24px);
      }
    }

    img {
      height: 45px;
      width: 45px;
    }
  }
}
