@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import '../../../assets/scss/mixins';

.TagFollow {
  align-items: center;
  background-color: white;
  border-radius: 30px;
  border: 1px solid #CCCCCC;
  color: $text_primary_dark_color;
  cursor: pointer;
  display: flex;
  font-family: $primary_font_book;
  font-size: pxToRem(13px);
  height: 39px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0 10px 0 10px;
  transition: border 0.8s ease;
  width: auto;

  &:hover {
    filter: brightness(0.9);
  }

  &.active {
    background-color: $background_primary_blue_color;
    color: $text_primary_light_color;
    transition: border 0.5s ease;
  }

  &.invertColors {
    filter: invert(2);
  }

  @include breakpoint-for(desktop-large) {
    font-size: pxToRem(17px);
    padding: 0 17px 0 17px;
  }
}
