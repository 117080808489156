@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.Sectionlanding {
  padding-bottom: 50px;

  @include breakpoint-for(desktop-small) {
    padding: 0;
  }
  
  .list-box-section {
    height: 267px;

    .box-navigator {
      background-color: #010230;
      border: 2px solid #FFFFFF;
      height: 174px;
      left: 60px;
      position: relative;
      top: 120px;
      width: 80%;

      &.content {
        left: 30px;
        top: -90px;
      }

      @include breakpoint-for(desktop-small) {
        height: 374px;
        left: 50px;
        top: -281px;
        width: 70%;

        &.content {
          left: 250px;
          top: -481px;
        }
      }

      @include breakpoint-for(desktop-medium) {
        width: 931px;
      }

      .top-navigator {
        border-bottom: 1px solid #FFFFFF;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .image {
          border-left: 1px solid #FFFFFF;
          height: 100%;
          padding: 5px 10px;

          img {
            height: 10px;
            width: 10px;
          }
        }

        @include breakpoint-for(desktop-small) {
          border-bottom: 2px solid #FFFFFF;

          .image {
            border-left: 2px solid #FFFFFF;
            height: 100%;
            padding: 15px 18px;

            img {
              height: 22px;
              width: 22px;
            }
          }
        }
      }

      .content-box {
        align-items: flex-start;
        display: flex;
        height: 100%;
        padding: 30px 24px;
        width: 100%;

        p {
          color: $text_primary_light_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(24px);
          margin: 0;
        }

        @include breakpoint-for(desktop-small) {
          align-items: center;
          padding: 20px 64px 40px 64px;

          p {
            font-size: pxToRem(50px);
          }
        }

        @include breakpoint-for(desktop-medium) {
          p {
            font-size: pxToRem(69px);
          }
        }
      }
    }
  }

  .infos-portal {
    gap: 30px;
    margin-top: 50px;
    padding: 0 20px;

    @include breakpoint-for(desktop-small) {
      display: flex;
      padding: 0 72px 173px;
    }

    .box-info {
      align-items: center;
      color: #010E63;
      display: flex;
      margin-bottom: 40px;

      .images-info {
        background: #FAFBFB;
        padding: 73px 15px;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          padding: 73px 53px;
        }

        strong {
          font-family: $primary_font_demi;
          font-size: pxToRem(25px);
        }


        &.active {
          background-color: #010230;
          color: $background_white_color;

          .container-images {
            .image-info {
              border: 2px solid #FFFFFF;
              background-color: #010230;

              p {
                color: $background_white_color;
              }
            }
          }
        }

        .container-images {
          display: flex;
          flex-wrap: wrap;
          gap: 8px 2px;
          justify-content: space-between;
          margin-top: 73px;

          @include breakpoint-for(desktop-small) {
            gap: 25px;
          }

          .image-info {
            background-color: $background_white_color;
            border: 2px solid #010E63;
            display: flex;
            flex-direction: column;
            height: 130px;
            justify-content: space-between;
            min-width: 140px;
            padding: 13px 17px;
            width: 49%;

            .image {
              display: flex;
              justify-content: flex-end;
              width: 100%;

              img {
                height: 15px;
                width: 15px;
              }
            }

            p {
              color: #010230;
              font-family: $primary_font_book;
              font-size: pxToRem(12px);
            }

            @include breakpoint-for(desktop-small) {
              height: 169px;
              width: 160px;

              p {
                font-size: pxToRem(13px);
              }

              img {
                height: 25px;
                width: 25px;
              }
            }

            @include breakpoint-for(desktop-medium) {
              height: 169px;
              width: 220px;

              p {
                font-size: pxToRem(16px);
              }
            }
          }
        }
      }
    }
  }
}