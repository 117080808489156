@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.Home {
  max-height: 100vh;
  overflow-y: scroll;
  padding: 0 15px 100px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 0 42px 100px;
  }

  .apresentation-home {
    background-color: $background_primary_blue_color;
    border-radius: 30px;
    display: flex;
    height: 250px;
    justify-content: space-between;
    margin-top: 68px;
    overflow: hidden;
    padding: 0 90px 0 87px;
    position: relative;
    z-index: 2;

    div {
      color: $text_primary_light_color;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      text-align: left;

      strong {
        font-family: $primary_font_demi;
        font-size: pxToRem(42px);
        margin-bottom: 22px;
      }

      p {
        font-family: $primary_font_book;
        font-size: pxToRem(24px);
        margin: 0;
      }
    }

    img {
      height: 377px;
      position: relative;
      right: 3%;
      top: 12%;
      width: 377px;
      z-index: 1;
    }
  }

  .container-filter {
    align-items: center;
    background-color: $background_primary_light_color;
    border-radius: 29px;
    display: flex;
    margin-top: 20px;
    min-height: 243px;
    padding: 30px 100px;
  }

  .search-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    width: 100%;

    .SearchTag {
      height: 100%;
      width: 100%;
    }

    .container-search {
      align-items: center;
      background-color: $background_primary_light_color;
      border-radius: 29px;
      display: flex;
      height: 100%;
      justify-content: space-around;
      padding: 30px 20px;
      width: 100%;

      .Button {
        height: 297px;
        width: 160px;
      }
    }
  }

  // student style
  .apresentation-mobile {
    align-items: center;
    background: #00016e 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    height: 133px;
    justify-content: flex-start;
    margin-top: 20px;
    padding: 39px 15px;
    width: 100%;

    .text-apresentation {
      color: $text_primary_light_color;
      margin-left: 13px;

      span {
        font-family: $primary_font_demi;
        font-size: pxToRem(16px);
      }

      p {
        font-family: $primary_font_demi;
        font-size: pxToRem(12px);
        font-weight: 500;
        margin: 7px 0 0 0;

        strong {
          color: #01C7F0;
          font-family: $primary_font_book;
        }
      }
    }

    img {
      border-radius: 50%;
      border: 2px solid #7b7eff;
      height: 38px;
      padding: 4px;
      width: 38px;
    }

    @include breakpoint-for(desktop-small) {
      display: none;
    }
  }

  .container-student-home {
    align-items: center;
    background: #fafbfb 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 39px 15px;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      border-radius: 29px;
      padding: 46px 69px 27px;
    }

    &.jobs {
      .search-mobile {
        align-items: flex-start;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-bottom: 59px;
        width: 100%;

        @include breakpoint-for(desktop-small) {
          display: none;
        }

        span {
          color: $text_primary_dark_color;
          font-family: $primary_font_book;
          font-size: pxToRem(20px);
          font-weight: 500;
          margin-bottom: 29px;
        }

        .container-search {
          align-items: center;
          justify-content: space-between;
          display: flex;
          height: 49px;
          width: 100%;

          .search-button-mobile {
            background-color: #00016e;
            border-radius: 10px;
            border: none;
            height: 52px;
            margin-left: 8px;
            min-width: 13%;
            width: 49px;

            img {
              height: 8px;
              transform: rotate(-90deg);
              width: 20px;
            }
          }
        }

        .search-bar-mobile {
          align-items: center;
          background-color: $background_white_color;
          border-radius: 5px;
          border: 1px solid #e8e8e8;
          display: flex;
          width: 85%;

          .search-button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            height: 100%;
            margin: 10px;
            padding: 5px;
            width: 20px;
          }

          .search-input {
            background-color: inherit;
            background-color: transparent;
            border: none;
            flex: 1;
            font-family: $primary_font_book;
            font-size: pxToRem(17px);
            height: 85%;
            outline: none;
            padding: 5px;

            &::placeholder {
              color: $text_primary_light_placeholder_color;
            }
          }
        }
      }

      .container-buttons {
        display: flex;
        gap: 0 20px;
        margin-top: 15px;

        .button-pagination {
          background-color: transparent;
          border: none;
          color: #c3c3c3;
          cursor: pointer;
          font-family: $primary_font_book;
          font-size: pxToRem(25px);

          &.active {
            color: #4d5a60;
          }
        }
      }
    }

    &.slide {
      display: none;
      padding: 46px 30px 27px;

      .recommentation-job-home {
        padding-left: 39px;
      }
    }

    &.one {
      margin-top: 75px;
      display: none;

      @include breakpoint-for(desktop-small) {
        display: flex;
      }
    }

    .apresentation-home {
      margin: 0;
      width: 100%;

      img {
        height: 252px;
        position: relative;
        right: 3%;
        top: -4px;
        width: 310px;
        z-index: 1;
      }
    }

    .search-bar {
      margin-top: 26px;
      align-items: center;
      background-color: $background_white_color;
      border-radius: 34px;
      border: 1px solid #e8e8e8;
      display: flex;
      height: 68px;
      width: 100%;

      form {
        width: 100%;
      }

      .search-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 100%;
        margin: 10px;
        padding: 5px;
        width: 60px;
      }

      .search-input {
        background-color: inherit;
        background-color: transparent;
        border: none;
        flex: 1;
        font-family: $primary_font_book;
        font-size: pxToRem(17px);
        height: 85%;
        width: 80%;
        outline: none;
        padding: 5px;

        &::placeholder {
          color: $text_primary_light_placeholder_color;
        }
      }
    }

    .recommentation-job-home {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      img {
        height: 51px;
        margin-right: 17px;
        width: 20px;
      }

      p {
        color: $text_secondary_dark_color;
        font-family: $primary_font_book;
        font-size: pxToRem(16px);
      }

      @include breakpoint-for(desktop-small) {
        img {
          height: 51px;
          margin-right: 17px;
          width: 24px;
        }

        p {
          color: $text_secondary_dark_color;
          font-family: $primary_font_demi;
          font-size: pxToRem(18px);
        }
      }
    }

    .list-recommendation-jobs {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: flex-start;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: flex-start;
        padding: 50px 0px;
        width: 100%;
      }
    }

    .list-recommendation-company {
      width: 100%;
    }
  }
}
