@import '../../../assets/scss/variables';
@import '../../../assets/scss/function';
@import '../../../assets/scss/mixins';

.DetailedFilter {
  width: 100%;

  .search-bar {
    align-items: center;
    background-color: $background_white_color;
    border-radius: 34px;
    border: 1px solid #E8E8E8;
    display: flex;
    height: 68px;
    width: 100%;
  }

  .search-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 100%;
    margin: 10px;
    padding: 5px;
    width: 60px;
  }

  .search-input {
    background-color: inherit;
    background-color: transparent;
    border: none;
    flex: 1;
    font-family: $primary_font_book;
    font-size: pxToRem(17px);
    height: 85%;
    outline: none;
    padding: 5px;

    &::placeholder {
      color: $text_primary_light_placeholder_color;
    }
  }

  .container-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 15.9px;
    justify-content: flex-start;
    margin-top: 20px;
    width: 100%;

    @include breakpoint-for(desktop-extra-large) {
      justify-content: space-between;
    }
  }
}
