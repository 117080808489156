@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.JobDetails {
  @include breakpoint-for(desktop-extra-large-4k) {
    max-width: 1511px;
  }

  .images-container {
    position: relative;

    .background {
      border-radius: 20px;
      height: 248px;
      width: 100%;
    }

    .profile {
      border-radius: 50%;
      border: 3px solid #ffffff;
      bottom: -60px;
      height: 153px;
      left: 60px;
      position: absolute;
      width: 153px;

      @include breakpoint-for(desktop-large) {
        left: 100px;
      }
    }
  }

  .container-info {
    .info-button {
      display: flex;
      flex-direction: column;

      .first-container-info {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0 220px;

        @include breakpoint-for(desktop-large) {
          margin: 20px 0 0 280px;
        }

        .title-and-info {
          h3 {
            color: $text_primary_dark_color;
            font-family: $primary_font_demi;
            font-size: pxToRem(30px);
            margin: 0;
          }

          div {
            display: flex;
            flex-direction: column;
            gap: 11px 0;
            margin-top: 29px;

            p {
              align-items: center;
              display: flex;
              font-family: $primary_font_book;
              font-size: pxToRem(14px);

              img {
                height: 20px;
                margin-right: 11px;
                width: 20px;
              }
            }
          }
        }

        .Button {
          height: 77px;
          font-size: pxToRem(21px);
        }
      }

      .box-description {
        background-color: $background_primary_light_color;
        border-radius: 29px;
        margin-top: 25px;
        margin: 50px 0 0 150px;
        padding: 45px 70px;

        @include breakpoint-for(desktop-large) {
          margin: 50px 0 0 210px;
        }

        h4 {
          font-family: $primary_font_demi;
          font-size: pxToRem(21px);
          margin: 0 0 31px 0;
        }

        p {
          font-family: $primary_font_book;
          font-size: pxToRem(19px);
        }
      }
    }
  }

  .info-jobs {
    font-family: $primary_font_book;
    font-size: pxToRem(19px);
    margin: 76px 0 !important;
    text-align: center;
    width: 100%;
  }
}
