@import "../../assets/scss/variables";
@import "../../assets/scss/function";
@import "../../assets/scss/mixins";

.PageNotFound {
  align-items: center;
  background-color: #010232;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 15px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 0;
  }

  strong {
    color: white;
    font-family: $primary_font_book;
    font-size: pxToRem(23px);
    margin: 40px 0 0 0;
    text-align: center;

    @include breakpoint-for(desktop-small) {
      font-size: pxToRem(40px);
    }
  }

  p {
    color: white;
    font-family: $primary_font_book;
    font-size: pxToRem(18px);
    margin: 40px 0;
    text-align: center;

    @include breakpoint-for(desktop-small) {
      font-size: pxToRem(17px);
    }
  }

  .Button {
    border-radius: 9px;
  }
}
